import React, { useContext } from 'react';
import { GlobalContext } from '../../global-context';

import { makeStyles, Grid, Typography } from '@material-ui/core';

const log = false;

const useStyles = makeStyles(theme => ({

  // Small Classes
  sm_step1: {
    position: 'relative',
    height: '100%',
    borderRadius: '20px 0 0 20px',
    marginRight: theme.spacing(1),
    background: '#00000050',
    '&::after': {
      content: `content`,
      position: 'absolute',
      right: 0,
      background: theme.palette.secondary.light,
    },
  },
  sm_step2: {
    position: 'relative',
    height: '100%',
    background: theme.palette.secondary.light,
  },
  sm_step3: {
    position: 'relative',
    height: '100%',
    borderRadius: '0 20px 20px 0',
    marginLeft: theme.spacing(1),
    background: theme.palette.secondary.light,
  },
  sm_stepTxt: {
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },

  // Large Classes
  lg_step1: {
    position: 'relative',
    height: '100%',
    borderRadius: '48px 0 0 48px',
    marginRight: theme.spacing(1.5),
    background: '#00000050',
    '&::after': {
      content: `content`,
      position: 'absolute',
      right: 0,
      background: '#00000050',
    },
  },
  lg_step2: {
    position: 'relative',
    height: '100%',
    background: '#00000050',
  },
  lg_step3: {
    position: 'relative',
    height: '100%',
    borderRadius: '0 48px 48px 0',
    marginLeft: theme.spacing(1.5),
    background: '#00000050',
  },
  lg_stepTxt: {
    position: 'absolute',
    top: '22.5%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  sm_detailTxt: {
    position: 'absolute',
    top: '60%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
}));

export default function OrderStatusTracker(props) {
  const { theme } = useContext(GlobalContext);
  const cls = useStyles();
  const { order, size } = props;

  let cancelStatus = order.cancel_status;
  let status = order.status;

  let label1 = ``;
  let label2 = ``;
  let label3 = ``;
  let detail = ``;
  let step = 0;
  let pulse = true;

  if (status) {
    if (status === `Pending`) {
      label1 = 'PROCESSING';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Cleansing address`;
      step = 1;
    }
    else if (status === `New`) {
      label1 = 'PROCESSING';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Calculating dates`;
      step = 1;
    }
    else if (status === `Invalid`) {
      label1 = 'ERROR';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `See address`;
      step = 1;
    }
    else if (status === `Canceled`) {
      label1 = 'CANCELED';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Customer canceled`;
      step = 1;
      pulse = false;
    }
    else if (status === `Ready`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Awaiting shipping`;
      step = 1;
    }
    else if (status === `Preparing`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Preparing shipment`;
      step = 2;
    }
    else if (status === `Outbound`) {
      label1 = 'READY';
      label2 = 'EN ROUTE';
      label3 = 'INBOUND';
      detail = `Shipping to customer`;
      step = 2;
    }
    else if (status === `Delivered`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'IN USE';
      detail = `Customer received booth`;
      step = 3;
    }
    else if (status === `Packing`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'PACKING';
      detail = `Customer packing booth`;
      step = 3;
    }
    else if (status === `Inbound`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Returning from customer`;
      step = 3;
    }
    else if (status === `Claim`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'ERROR';
      detail = `Problem during shipping`;
      step = 3;
    }
    else if (status === `Returned`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'RETURNED';
      detail = `Booth received from customer`;
      step = 3;
    }
    else if (status === `Checkin`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Processing inbound booth`;
      step = 3;
    }
    else if (status === `Done`) {
      label1 = 'READY';
      label2 = 'OUTBOUND';
      label3 = 'INBOUND';
      detail = `Order complete`;
      pulse = false;
    }
    else {
      pulse = false;
    }
  }
  else {
    step = 1;
  }

  if (cancelStatus) {
    pulse = false;
  }

  log && console.log(`Label:`, detail);

  let activeHue = theme.palette.primary.main;
  let deactiveHue = theme.palette.text.disabled;
  let errorHue = theme.palette.error.dark;

  let t1Hue = label1 != 'ERROR' ? step === 0 || step === 1 ? activeHue : activeHue : errorHue;
  let t2Hue = label2 != 'ERROR' ? step === 0 || step === 2 ? activeHue : (step === 3 ? activeHue : deactiveHue) : errorHue;
  let t3Hue = label3 != 'ERROR' ? step === 0 || step === 3 ? activeHue : deactiveHue : errorHue;
  let t1Pulse = !pulse || (step !== 0 && step !== 1) ? `none` : `pulse 1s infinite`;
  let t2Pulse = !pulse || (step !== 0 && step !== 2) ? `none` : `pulse 1s infinite`;
  let t3Pulse = !pulse || (step !== 0 && step !== 3) ? `none` : `pulse 1s infinite`;

  return (<>
    {size === `small` ?
      <>
        <div className={cls.sm_tracker}>
          <Grid container spacing={0} wrap="nowrap" style={{ height: '40px' }}>
            <Grid item xs={2}>
              <div className={cls.sm_step1} style={{ background: (label1==='ERROR')?errorHue:t1Hue, boxShadow: `0 0 0 0 ${t1Hue}`, animation: t1Pulse }}>
                <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>{label1}</Typography>
                <Typography className={cls.sm_detailTxt} style={{ color: '#fff' }}>{step === 1 ? detail : ''}</Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={cls.sm_step2} style={{ background: t2Hue, boxShadow: `0 0 0 0 ${t2Hue}`, animation: t2Pulse }}>
                <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>{label2}</Typography>
                <Typography className={cls.sm_detailTxt} style={{ color: '#fff' }}>{step === 2 ? detail : ''}</Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={cls.sm_step3} style={{ background: t3Hue, boxShadow: `0 0 0 0 ${t3Hue}`, animation: t3Pulse }}>
                <Typography className={cls.sm_stepTxt} style={{ color: '#fff' }}>{label3}</Typography>
                <Typography className={cls.sm_detailTxt} style={{ color: '#fff' }}>{step === 3 ? detail : ''}</Typography>
                
              </div>
            </Grid>
          </Grid>
        </div>
      </> :
      <>
        <div className={cls.lg_tracker}>
          <Grid container spacing={0} wrap="nowrap" style={{ height: '64px' }}>
            <Grid item xs={2}>
              <div className={cls.lg_step1} style={{ background: t1Hue, boxShadow: `0 0 0 0 ${t1Hue}`, animation: t1Pulse }}>
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>{label1}</Typography>
                <Typography className={cls.sm_detailTxt} style={{ color: '#fff' }}>{step === 1 ? detail : ''}</Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={cls.lg_step2} style={{ background: t2Hue, boxShadow: `0 0 0 0 ${t2Hue}`, animation: t2Pulse }}>
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>{label2}</Typography>
                <Typography className={cls.sm_detailTxt} style={{ color: '#fff' }}>{step === 2 ? detail : ''}</Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className={cls.lg_step3} style={{ background: t3Hue, boxShadow: `0 0 0 0 ${t3Hue}`, animation: t3Pulse }}>
                <Typography className={cls.lg_stepTxt} style={{ color: '#fff' }}>{label3}</Typography>
                <Typography className={cls.sm_detailTxt} style={{ color: '#fff' }}>{step === 3 ? detail : ''}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    }
  </>)
}