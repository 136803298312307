import React, { useState, useContext, useEffect } from 'react';
import { InputAdornment, OutlinedInput, Icon, IconButton, makeStyles, Grid, Typography, TextField, Button, FormControl, InputLabel, Tooltip, Avatar, ListItemText, ListItem, ListItemAvatar, List, Divider } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { GlobalContext } from '../../../global-context';
import moment from 'moment';

const log = false;

export default function OrderDetailsNotes(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const { orderId, notes } = props;

  const [note, setNote] = useState('');

  useEffect(() => {
    log && console.log(`Rendering ${notes.length} order notes for order #${orderId}`)
  }, [orderId, notes]);

  const handleChange = (name, value) => event => {
    setNote(value === undefined ? event.target.value : value)
  };

  const handleAddNote = async () => {
    console.log('Saving new note: ' + note, ctx.userProfile)
    await ctx.BoothByMail.orders.addNotes([{
      order_id: orderId,
      user_id: ctx.userProfile.bbm_user_id,
      type: 'Note',
      body: note,
    }])
    setNote('')
  }

  const convertLineBreaks = body => {
    const newBody = body.replace(/<br>/g, '\n')
    return (
      <>
      {
        newBody.split('\n').map((item, i) => {
          return <React.Fragment key={i}>{item}<br /></React.Fragment>
        })
      }
      </>
    )
  }

  return (<>
      <Grid container spacing={2} alignItems="flex-start" wrap="nowrap">
        <Grid item sm={12} xs={12}>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="note"
              multiline
              placeholder="Leave a comment..."
              onChange={handleChange('note')}
              value={note}
              endAdornment={
                <InputAdornment position="end" style={{bottom: '0px'}}>
                  <IconButton
                    aria-label="submit a note"
                    onClick={handleAddNote}
                    edge="end"
                  >
                    <Icon>send</Icon>
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <div className={cls.mediumBreak} />

      {notes && notes.length > 0 ?
        <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
          <Grid item sm={12} xs={12}>
            <List className={cls.root}>
              {notes.map(note => (
              <div key={note.id}>
                <ListItem>
                  <Tooltip title={note && note.user && note.user.name}>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </Tooltip>
                  <ListItemText primary={convertLineBreaks(note.body)} secondary={`${note && note.user && note.user.name} - ${moment(note.createdat).format('LL hh:mm:ss')}`} />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>))}
            </List>
          </Grid>
        </Grid>
      :
          <Typography className={cls.notFoundTxt}>NO NOTES FOUND</Typography>
      }
      
  </>)
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  valTxt: {
    width: '100%',
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  mediumBreak: {
    width: '100%',
    height: theme.spacing(2),
  },
}));