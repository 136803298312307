import React, { useState, useEffect } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import moment from 'moment-business-days';

const {REACT_APP_SHIPPING_HOLIDAYS, REACT_APP_SHIPPING_HOLIDAYS_FORMAT, REACT_APP_SHIPPING_WEEKDAYS, REACT_APP_SHIPPING_BUFFER_DAYS} = process.env

const log = false

export default function OrderDetailsCalendar({ orderId, transitDays, shipDate, arrivalDate, eventDate, returnShipDate, returnArrivalDate }) {
  const cls = useStyles();

  const [shipDateObj, setShipDateObj] = useState(null)
  const [arrivalDateObj, setArrivalDateObj] = useState(null)
  const [eventDateObj, setEventDateObj] = useState(null)
  const [returnShipDateObj, setReturnShipDateObj] = useState(null)
  const [returnArrivalDateObj, setReturnArrivalDateObj] = useState(null)

  useEffect(() => {
    log && console.log(`Rendering mini calendar starting on ship date ${shipDate} going till return arrival date ${returnArrivalDate}`);
    
    // Defines days from 1 (Monday) to 5 (Friday) as business days
    try {
      const shippingHolidays = JSON.parse(REACT_APP_SHIPPING_HOLIDAYS).holidays
      const shippingWeekdays = JSON.parse(REACT_APP_SHIPPING_WEEKDAYS).weekdays
      moment.updateLocale('us', {
        holidays: shippingHolidays,
        holidayFormat: REACT_APP_SHIPPING_HOLIDAYS_FORMAT,
        workingWeekdays: shippingWeekdays
      })
    } catch (error) {
      console.log('Error setting business days. Using defaults.', error)
      moment.updateLocale('us', {
        holidays: ['01-01-2020', '05-27-2020', '07-04-2020', '09-02-2020', '11-28-2020', '12-25-2020'],  //Use 2020 dates
        holidayFormat: 'MM-DD-YYYY',
        workingWeekdays: [1, 2, 3, 4, 5]  //Use monday through friday only
      })
    }

    if (shipDate) {
      setShipDateObj(moment(shipDate))
    } else {
      setShipDateObj(moment())
    }

    if (arrivalDate) {
      setArrivalDateObj(moment(arrivalDate))
    } else {
      setArrivalDateObj(moment())
    }

    if (eventDate) {
      setEventDateObj(moment(eventDate))
    } else {
      setEventDateObj(moment())
    }

    if (returnShipDate) {
      setReturnShipDateObj(moment(returnShipDate))
    } else {
      setReturnShipDateObj(moment(eventDate).businessAdd(1, "day"))
    }

    if (returnArrivalDate) {
      setReturnArrivalDateObj(moment(returnArrivalDate))
    } else {
      setReturnArrivalDateObj(moment())
    }
  }, [orderId, transitDays, shipDate, arrivalDate, eventDate, returnShipDate, returnArrivalDate])

  const Weeks = props => {
    let daysInMonth = eventDateObj.daysInMonth()
    let firstDay = weekdayNumOfFirstDay()
    log && console.log(`First day: ${firstDay}`)
    const dayCountWithBlanks = firstDay + daysInMonth
    let weeks = Math.ceil(dayCountWithBlanks / 7)
    const startOfMonth = eventDateObj.clone().startOf("month")
    let dateOfCellOne = startOfMonth.clone().subtract(firstDay, 'days')
    log && console.log('Date of cell one: ', dateOfCellOne.format('MM-DD-YYYY'))
    log && console.log(`Rendering ${dayCountWithBlanks} days (${firstDay} + ${daysInMonth}) across ${weeks} weeks (${dayCountWithBlanks} / 7 = ${dayCountWithBlanks / 7}) in ${eventDateObj.format('MMMM')}`)
    return <>{[...Array(weeks)].map((w, i) => {
      let days = []
      if (i == 0) {
        for (var ii = 0; ii < 7; ii++) {
          if (ii < firstDay) {
            days.push(Number(dateOfCellOne.format('D')) + ii)
          }else{
            days.push(ii - firstDay + 1)
          }
        }
      } else if (i == weeks - 1) {
        for (var ii = 0; ii < 7; ii++) {
          if ((i * 7) - firstDay + ii >= daysInMonth) {
            const nextMonthDay = daysInMonth - (i * 7) + ii + firstDay - 1
            days.push(nextMonthDay)
          }else{
            days.push((i * 7) - firstDay + 1 + ii)
          }
        }
      } else {
        days = range((i * 7) - firstDay + 1, (i * 7) - firstDay + 7)
      }
      log && console.log(`  Rendering week ${i} with days: ${JSON.stringify(days)}`)
      return <Week key={'week'+i} week={i} days={days} start={dateOfCellOne} />
    })}</>
  }

  const Week = ({ week, days, start }) => {
    log && console.log(`  Rendering week ${week} with days ${Math.min(days)}-${Math.max(days)}`)
    return <>
      <tr>
        {days.map((d,i) => <Day key={d} day={d} date={start.clone().add((week * 7) + i, 'days')} />)}
      </tr>
    </>
  }

  const Day = ({ day, date }) => {
    log && console.log(`    Rendering day ${day}`)
    const classes = `${cls.calendarCell} ${cls.calendarDay} ${date.isBetween(shipDateObj.clone().subtract(1,'day'), arrivalDateObj) ? cls.calendarOutbound : ''} ${date.isBetween(returnShipDateObj.clone().subtract(1,'day'), returnArrivalDateObj) ? cls.calendarInbound : ''} ${date.isBetween(arrivalDateObj.clone().subtract(1,'day'), eventDateObj) ? cls.calendarBuffer : ''} ${date.isSame(eventDateObj, 'day') ? cls.calendarEvent : ''} ${date.isSame(returnArrivalDateObj, 'day') ? cls.calendarReturnArrival : ''} ${date.isSame(moment(), 'day') ? cls.calendarToday : ''}`
    return <Tooltip title={date.format('MM-DD-YYYY')}><td className={classes} key={day}><span >{day ? day : ''}</span></td></Tooltip>
  }

  const range = (lowEnd, highEnd) => {
    var list = [];
    for (var i = lowEnd; i <= highEnd; i++) {
        list.push(i);
    }
    return list
  }

  const weekdayNumOfFirstDay = () => {
    let firstDay = eventDateObj
      .clone()
      .startOf("month")
      .format("d"); // Day of week 0...1..5...6
      log && console.log(`First day of month: ${firstDay}`)
    return Number(firstDay);
  }

  return (<>
    {eventDateObj ? 
    <div className={cls.main}>
      <table className={cls.calendar}>
        <thead>
          {/*}
          <tr className={`${cls.calendarCell}`}>
            <th colSpan={7}>{eventDateObj.format('MMMM')}</th>
          </tr>
          */}
          <tr>
            {moment.weekdaysShort().map(day => {
              return (
                <th key={day} className={`${cls.calendarCell} ${cls.calendarHeader}`}>
                  {day}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <Weeks />
        </tbody>
      </table>
    </div>
  :<div>Loading...</div>}
  </>)
}

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    margin: 0,
    padding: 0,
    borderSpacing: 0,
    borderCollapse: 'collapse'
  },
  calendar: {
    width: '100%',
    margin: 0,
    padding: 0,
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  calendarCell: {
    width: '25px',
    height: '25px',
    padding: 0,
    textAlign: 'center',
    fontWeight: 'normal',
    lineHeight: '20px',
    border: 0,
  },
  calendarHeader: {
    color: '#334455',
    fontSize: '11px',
    fontWeight: 'bold',
    verticalAlign: 'top',
    backgroundColor: 'transparent',
  },
  calendarDay: {
    color: '#334455',
    cursor: 'pointer',
    position: 'relative',
    fontSize: '11px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    webkitBorderRadius: '50%',
  },
  calendarEvent: {
    color: '#ffffff',
    backgroundColor: '#3296C8',
  },
  calendarToday: {
    color: '#334455',
    backgroundColor: '#dff0d8',
  },
  calendarBuffer: {
    color: '#334455',
    backgroundColor: '#d9edf7',
  },
  calendarReturnArrival: {
    color: '#334455',
    backgroundColor: '#dff0d8',
  },
  calendarInbound: {
    color: '#334455',
    backgroundColor: '#faf2cc',
  },
  calendarOutbound: {
    color: '#334455',
    backgroundColor: '#faf2cc',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  list: {

  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  attempt: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '4px',
    marginTop: theme.spacing(1),
    background: '#00000004',
    "&:hover": {
      background: theme.palette.primary.main,
      color: '#fff',
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  attemptIdTxt: {
    verticalAlign: 'top',
    textAlign: 'left',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  attemptStatusTxt: {
    verticalAlign: 'top',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  attemptTimeTxt: {
    verticalAlign: 'top',
    textAlign: 'left',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  attemptCostTxt: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  sharedTxt: {
    float: 'left',
    verticalAlign: 'top',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  sharedItemTxt: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2)
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#eee',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));