import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, GridList, GridListTile, Paper, Icon, IconButton } from '@material-ui/core';
import SessionCard from './SessionCard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const moment = require('moment');

const log = false;

export default function OrderDetailsSessions({ albumKey, sessions, ...props }) {
  const cls = useStyles();
  const albumLink = `https://photos${
    process.env.REACT_APP_SITE_ENV === 'test' ? '-test' : ''
  }.boothbymail.com/a/${albumKey}`;

  const copyAlbumLink = () => {
    navigator.clipboard.writeText(albumLink);
  };

  return (
    <>
      {sessions && sessions.length > 0 ? (
        <>
          <Grid container spacing={5}>
            <Grid item xs={12} align='center'>
              <a href={albumLink} target='_blank' rel='noopener'>
                {albumLink}
              </a>
              <IconButton color='secondary' style={{ marginLeft: '10px' }} size='small' onClick={copyAlbumLink}>
                <FileCopyIcon />
              </IconButton>
            </Grid>
            {sessions.map(
              session =>
                session.photos.length > 0 && (
                  <Grid item md={3} xs={12} key={session.id}>
                    <SessionCard
                      photos={session.photos.filter(photo => photo.name !== 'sign.png' && photo.name !== 'strip.jpg')}
                      message={session.photos.find(photo => photo.name === 'sign.png')}
                      strip={session.photos.find(photo => photo.name === 'strip.jpg')}
                      title={moment.duration(moment().diff(moment(session.createdat))).humanize() + ' ago'}
                      caption={session.id}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </>
      ) : (
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO SESSIONS FOUND YET</Typography>
        </div>
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  valTxt: {
    width: '100%',
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));
