import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/utils/PrivateRoute";
import LoginPage from "./components/pages/Login";
import ProfilePage from "./components/pages/Profile";
import Dashboard from "./components/pages/Dashboard";
import OrdersPage from "./components/pages/Orders";
import SearchResults from "./components/pages/SearchResults";
import Order from "./components/pages/Order";
import OutboundSchedule from "./components/pages/OutboundSchedule";
import InboundSchedule from "./components/pages/InboundSchedule";
import EventSchedule from "./components/pages/EventSchedule";
import OutboundReport from "./components/pages/OutboundReport";
import InboundReport from "./components/pages/InboundReport";
import OpenComplaints from "./components/pages/OpenComplaints";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        
        <Route path="/login" exact component={LoginPage} />
        <PrivateRoute path="/profile" exact component={ProfilePage} />

        <PrivateRoute path="/" exact component={OrdersPage} />
        <PrivateRoute path="/orders" exact component={OrdersPage} />
        <PrivateRoute path="/orders/searchresults/" exact component={(props) => <SearchResults {...props} />} />
        <PrivateRoute path="/orders/:id/" exact component={Order} />
        <PrivateRoute path="/outbound-schedule" exact component={OutboundSchedule} />
        <PrivateRoute path="/inbound-schedule" exact component={InboundSchedule} />
        <PrivateRoute path="/event-schedule" exact component={EventSchedule} />
        <PrivateRoute path="/outbound-report" exact component={OutboundReport} />
        <PrivateRoute path="/inbound-report" exact component={InboundReport} />
        <PrivateRoute path="/complaints-report" exact component={OpenComplaints} />

      </Switch>
    );
  }
}

export default Routes;
