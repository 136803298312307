import React from 'react';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import OrderCard from './OrderCard'
import PrintableShippingLabels from './PrintableShippingLabels'
import { makeStyles, Typography, Tooltip, Icon, IconButton, Button, Box, Paper, Grid, Card, CardContent, Chip } from '@material-ui/core';

function WeeklyScheduleWeekday({ sundayMoment, offsetDays = 0, orders }) {
    const cls = useStyles();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box p={2} borderTop={1} borderBottom={1} borderLeft={1} borderRight={offsetDays == 6 ? 1 : 0} borderColor="grey.500" style={{ paddingBottom: '4px' }}>
                    <Grid container className={cls.rowTxt} alignItems="flex-end">
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{moment.weekdaysShort(sundayMoment.clone().add(offsetDays, 'day').weekday())}</Typography>
                        </Grid>
                        <Grid item xs={6} className={`${cls.alignRight}`}>
                            {orders.length > 0 &&
                                <Tooltip title={`${orders.length} orders`}><Chip className={cls.rounded}size="small" color="primary" label={orders.length} /></Tooltip>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h3">{sundayMoment.clone().add(offsetDays, 'day').date()}</Typography>
                        </Grid>
                        <Grid item xs={6} className={`${cls.alignRight}`}>
                            {orders.length > 0 &&
                                <PrintableShippingLabels orders={orders} type="all" hide trigger={<Button size="small" style={{ marginBottom: "12px" }} variant="contained" color='primary' startIcon={<Icon fontSize='small'>print</Icon>}>Print</Button>} />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box p={2} borderTop={0} borderBottom={1} borderLeft={1} borderRight={offsetDays == 6 ? 1 : 0} borderColor="grey.500" height="100vh">
                    <Grid container spacing={4}>
                        {orders.map(order => (
                            <Grid item xs={12} padding={2} key={order.id}>
                                <OrderCard order={order} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default withRouter(WeeklyScheduleWeekday);

const useStyles = makeStyles(theme => ({
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  rounded:{
    borderRadius:'.25rem'
  }
}));