import React from 'react';
import { makeStyles, Grid, TextField, MenuItem, Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default function OrdersFilter(props) {
  const cls = useStyles();

  const { status, range, start, end, onStatusChange, onRangeChange, onDateChange, onNullsChange, disablePickers } = props;

  const handleStatusChange = event => {
    if (event.target.value !== `all`) onStatusChange(event.target.value);
    else onStatusChange(null);
  }

  const handleRangeChange = event => {
    onRangeChange(event.target.value);
  }

  const handleDateChange = name => event => {
    onDateChange(event, name);
  }

  const handleNullsChange = event => {
    onNullsChange(event.target.checked);
  }

  return (<>
    <div className={cls.main}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <Grid container spacing={2}>

          <Grid item md={3} sm={6} xs={12}>
            <TextField
              select
              fullWidth
              label="Status Filter"
              placeholder="Select a status filter..."
              variant="outlined"
              margin="dense"
              value={status || `All`}
              onChange={handleStatusChange}
              className={cls.input}
            >
              <MenuItem value={`All`}>All</MenuItem>
              <MenuItem value={`Not Done`}>Not Done</MenuItem>
              <MenuItem value={`Pending`}>Pending</MenuItem>
              <MenuItem value={`New`}>New</MenuItem>
              <MenuItem value={`Ready`}>Ready</MenuItem>
              <MenuItem value={`Preparing`}>Preparing</MenuItem>
              <MenuItem value={`Outbound`}>Outbound</MenuItem>
              <MenuItem value={`Delivered`}>Delivered</MenuItem>
              <MenuItem value={`Packing`}>Packing</MenuItem>
              <MenuItem value={`Inbound`}>Inbound</MenuItem>
              <MenuItem value={`Returned`}>Returned</MenuItem>
              <MenuItem value={`Checkin`}>Checkin</MenuItem>
              <MenuItem value={`Done`}>Done</MenuItem>
              <MenuItem value={`Canceled`}>Canceled</MenuItem>
              <MenuItem value={`Invalid`}>Invalid</MenuItem>
            </TextField>
          </Grid>

          <Grid item md={2} sm={6} xs={12}>
            <TextField
              select
              fullWidth
              label="Event Date Range"
              placeholder="Select a date range..."
              variant="outlined"
              margin="dense"
              value={range || `day`}
              onChange={handleRangeChange}
              className={cls.input}
            >
              <MenuItem value={`any`}>Any</MenuItem>
              <MenuItem value={`day`}>Today</MenuItem>
              <MenuItem value={`week`}>Next Week</MenuItem>
              <MenuItem value={`month`}>Next Month</MenuItem>
              <MenuItem value={`year`}>Next Year</MenuItem>
              <MenuItem value={`custom`}>Custom Range</MenuItem>
            </TextField>
          </Grid>

          <Grid item md={1} sm={6} xs={12}>
            <FormGroup> 
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    label="Nulls"
                    color="primary"
                    onChange={handleNullsChange}
                  />
                }
                label="Nulls"
              />
            </FormGroup>
          </Grid>

          <Grid item md={3} sm={3} xs={6}>
            <DatePicker
              disabled={disablePickers}
              autoOk
              maxDate={end}
              orientation="portrait"
              format="yyyy-MM-dd"
              label="Start Date"
              margin="dense"
              inputVariant="outlined"
              value={start}
              onChange={handleDateChange(`start`)}
              className={cls.date}
            />
          </Grid>

          <Grid item md={3} sm={3} xs={6}>
            <DatePicker
              disabled={disablePickers}
              autoOk
              minDate={start}
              orientation="portrait"
              format="yyyy-MM-dd"
              label="End Date"
              margin="dense"
              inputVariant="outlined"
              value={end}
              onChange={handleDateChange(`end`)}
              className={cls.date}
            />
          </Grid>

        </Grid>

      </MuiPickersUtilsProvider>
    </div>
  </>)
}

const useStyles = makeStyles(theme => ({
  input: {
    margin: 0,
  },
  date: {
    width: '100%',
    margin: 0,
  },
}));