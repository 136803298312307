import React, { useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Dialog,
  DialogActions,
  Button,
  Grid,
  TextField,
  IconButton,
  Icon,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GlobalContext } from '../../../global-context';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import boothbymail from '@BoothByMail/boothbymail-sdk';

let log = false;

export default function OutboundModal({ open, setOpen, order }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [loading, setLoading] = useState(false);
  const [selectedBoothID, setSelectedBoothID] = useState('');

  const handleStartOutbound = async () => {
    console.log('Handling outbound start by setting the order status to Preparing');
    boothbymail.orders.updateStatus(order.id, 'Preparing');
    await ctx.BoothByMail.orders.addNotes([
      {
        order_id: order.id,
        user_id: ctx.userProfile.bbm_user_id,
        type: 'Note',
        body: `Outbound started`,
      },
    ]);
  };

  const handleFinishOutbound = async () => {
    console.log('Handling outbound finish by setting the order status to Outbound');
    try {
      let change = {
        id: order.id,
        status: 'Outbound',
        booth_id: selectedBoothID,
      };
      const res = await boothbymail.orders.update(change);
      if (res) {
        await ctx.BoothByMail.orders.addNotes([
          {
            order_id: order.id,
            user_id: ctx.userProfile.bbm_user_id,
            type: 'Note',
            body: `Outbound completed using booth id: ${selectedBoothID}`,
          },
        ]);
        ctx.handleNotifications(true, `success`, `Outbound successfully completed using booth id ${selectedBoothID}`);
      }
    } catch (err) {
      console.log(`Failed to update order:`, err);
      ctx.handleNotifications(true, `error`, `Failed to complete outbound: ${err.toString()}`);
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Outbound</DialogTitle>
      <DialogContent>
        <DialogContentText>
          When you're ready, hit the start button to set the status of the order to Preparing to begin the outbound
          process. The customer will not be notified of this change.
          <br />
          <br />
          <Button disabled={order.status === 'Preparing'} color='primary' onClick={() => handleStartOutbound()}>
            Start
          </Button>
          <br />
          <br />
          Choose the booth id you want to assign to the order by selecting it from the drop down below. When ready, hit
          the done button to complete the outbound process and notify the customer that their order has shipped.
          <br />
          <br />
        </DialogContentText>

        <Query
          query={getBoothsWithStatusInfo}
          onError={err =>
            ctx.handleNotifications(true, 'error', 'Query failed to retrieve available booth list: ' + err.toString())
          }
        >
          {({ refetch, ...result }) => {
            if (result.loading) {
              return (
                <CircularProgress
                  style={{
                    display: 'block',
                    margin: 'auto',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                  }}
                />
              );
            }
            if (result.error) return `Error! ${result.error.message}`;
            const { booths = {} } = result.data;
            return (
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Autocomplete
                    id='booth-id-select'
                    options={booths}
                    getOptionLabel={booth => `${booth.id}`}
                    fullWidth
                    disabled={order.status !== 'Preparing'}
                    onInputChange={(event, newSelectedBoothID) => {
                      setSelectedBoothID(newSelectedBoothID);
                    }}
                    renderInput={params => <TextField {...params} label='Type a Booth ID...' variant='outlined' />}
                    renderOption={booth => {
                      try {
                        //Get the active order as an object if it exists in the response
                        let { activeOrder = [] } = booth;
                        activeOrder = Array.isArray(activeOrder) && activeOrder.length > 0 ? activeOrder[0] : {};

                        //Get the last order as an object if it exists in the response
                        let { lastOrder = [] } = booth;
                        lastOrder = Array.isArray(lastOrder) && lastOrder.length > 0 ? lastOrder[0] : {};

                        //If there is an active order then the booth is unavailable
                        const available = !Boolean(activeOrder.id);

                        return (
                          <Grid container alignItems='center'>
                            <Grid item>
                              <Icon className={cls.optionIcon}>{available ? 'tablet_mac' : 'no_cell'}</Icon>
                            </Grid>
                            <Grid item xs>
                              <strong>{booth.id}</strong>

                              {available && (
                                <Typography variant='body2' color='textSecondary'>
                                  <>
                                    Prior order {lastOrder.id} ({lastOrder.event_name}) in {lastOrder.status} status
                                  </>
                                </Typography>
                              )}

                              {!available && (
                                <Typography variant='body2' color='error'>
                                  <>
                                    Current order {activeOrder.id} ({activeOrder.event_name}) still in{' '}
                                    {activeOrder.status} status
                                  </>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        );
                      } catch (e) {
                        return <strong>{booth.id}</strong>;
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label='refresh booth list'
                    onClick={() => {
                      refetch();
                    }}
                    edge='end'
                  >
                    <Icon>cached</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            );
          }}
        </Query>
      </DialogContent>
      <DialogActions>
        <Button disabled={order.status === 'Preparing'} color='primary' onClick={() => handleStartOutbound()}>
          Start
        </Button>
        <Button disabled={order.status !== 'Preparing'} color='primary' onClick={() => handleFinishOutbound()}>
          Done
        </Button>
        <Button disabled={loading} color='secondary' onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
  optionIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const getBoothsWithStatusInfo = gql`
  query getBoothsWithStatusInfo {
    booths {
      id
      active
      build_date
      activeOrder: orders(
        where: { status: { _nin: ["Done", "Cancelled", "Canceled"] }, active: { _eq: true } }
        limit: 1
        order_by: { event_date: asc }
      ) {
        id
        status
        event_name
        event_date
      }
      lastOrder: orders(where: { active: { _eq: true } }, order_by: { event_date: desc }, limit: 1) {
        id
        status
        event_name
        event_date
      }
    }
  }
`;
