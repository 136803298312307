import React, { Component } from "react"
import { Route } from 'react-router-dom'
import { GlobalContext } from '../../global-context'
import Login from '../utils/Login'

const log = false

class PrivateRoute extends Component {

    render() {
        const { component: Component, ...rest } = this.props;

        const renderRoute = props => {
            if (this.context.userIsAuthenticated()) {
                return (
                    <React.Fragment>
                        <Component {...props} />
                    </React.Fragment>
                );
            }else{
                //Store the location they were trying to get to
                //The login will redirect back to LoginPage.js where these will be read in
                localStorage.setItem("privateRedirectPath", props.location.pathname)
                if(log){console.log('Login required. Saving redirect path: ', props.location.pathname)}
                return (
                    <Login />
                );
            }
        }

        return (
            <Route {...rest} render={renderRoute} />
        );
    }
}

PrivateRoute.contextType = GlobalContext;

export default PrivateRoute;