import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { LazyLog } from 'react-lazylog';
import moment from 'moment';

const log = false;

export default function OrderDetailsLogs(props) {
  const cls = useStyles();
  const { orderId, logs } = props;

  const [logText, setLogText] = useState('');

  const xlateTypeToEmoji = type => {
    switch (type) {
      case 'app':
        return '📷'
        break;
      case 'chip':
        return '⚙️'
        break;
      case 'server':
        return '🚀'
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    log && console.log(`Rendering ${logs.length} app logs for order #${orderId}`)
    setLogText(logs.sort((a,b) => {return new Date(a.createdat) - new Date(b.createdat)}).map(log => `[${moment(log.createdat).format('YYYY-MM-DD HH:mm:ss')} ${xlateTypeToEmoji(log.type)}${log.session_id ? ' ' + log.session_id : ''}] ${log.message}`).join("\n"))
  }, [orderId, logs]);

  return (<>
    {logs && logs.length > 0 ? <>
      <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <LazyLog follow={true} extraLines={1} enableSearch text={logText} caseInsensitive height={500} />
          </Grid>
      </Grid>
    </> :
      <div className={cls.notFound}>
        <Typography className={cls.notFoundTxt}>NO LOGS FOUND</Typography>
      </div>}
  </>)
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  valTxt: {
    width: '100%',
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));