import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';

import { makeStyles, Container, Typography, Tooltip, Icon } from '@material-ui/core';

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';

import Loading from '../utils/Loading';
import OrdersFilter from './orders/OrdersFilter';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

import fragments from '../utils/graphql/fragments';

const log = false;

const cap = (str) => {
  if (str) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
}

const titleCase = (str) => {
  return str.toLowerCase().split(' ').map(function(word) {
    if (word.length > 0) {
      return word.replace(word[0], word[0].toUpperCase());
    }else{
      return '';
    }
  }).join(' ');
}

const getDefaultRange = () => {
  const localRange = localStorage.getItem(`orders-index-range`);
  if (localRange) return localRange;
  else return `year`;
}

const getDefaultStart = () => {
  const localRange = localStorage.getItem(`orders-index-range`);
  const localStart = localStorage.getItem(`orders-index-start`);
  if (localRange === 'any') {
    return moment.utc(moment('2015-01-01')).format();
  }
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return moment.utc(moment().startOf(`day`).add(1, `week`)).format();
}
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`orders-index-range`);
  const localEnd = localStorage.getItem(`orders-index-end`);
  if (localRange === 'any') {
    return moment.utc(moment('2095-01-01')).format();
  }
  if (localRange && localRange !== `custom`) return moment.utc(moment().startOf(`day`).add(1, localRange)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return moment.utc(moment().startOf(`day`)).format();
}
const getDefaultNulls = () => {
  const localNulls = localStorage.getItem(`orders-null-event-dates`);
  return Boolean(localNulls || false);
}
const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`orders-index-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
}

const defaultOrder = `asc`;
const defaultOrderBy = `EVENT_DATE`;

function Orders(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [status, setStatus] = useState('Not Done');
  const [range, setRange] = useState(getDefaultRange());
  const [start, setStart] = useState(getDefaultStart());
  const [end, setEnd] = useState(getDefaultEnd());
  const [nulls, setNulls] = useState(getDefaultNulls())
  const [disablePickers, setDisablePickers] = useState(getDefaultDisable());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRowId, setExpandedRowId] = useState(0);

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`Range Change:`, value);
    localStorage.setItem(`orders-index-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      if (value === 'any') {
        setStart(moment.utc(moment('2015-01-01')).format());
        setEnd(moment.utc(moment('2095-01-01')).format());
      } else {
        setStart(moment.utc(moment().startOf(`day`)).format());
        setEnd(moment.utc(moment().startOf(`day`).add(1, value)).format());
      }
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  }
  
  //Handle the setting of allow nulls for event date filtering
  const handleNullsChange = value => {
    log && console.log(`Allow Nulls Change:`, value);
    localStorage.setItem(`orders-null-event-dates`, value);
    setNulls(value);
  }

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`orders-index-start`, newDate);
      setStart(newDate);
    }
    else {
      const newDate = moment.utc(moment(value).startOf(`day`)).format();
      localStorage.setItem(`orders-index-end`, newDate);
      setEnd(newDate);
    }
  }

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.event_name && o.event_name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address1 && (o.shipping_address1 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address2 && (o.shipping_address2 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_city && (o.shipping_city + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_state && (o.shipping_state + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_zip && (o.shipping_zip + ``).toLocaleLowerCase().includes(search)) ||
          (o.outbound_tracking && (o.outbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.inbound_tracking && (o.inbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.email && (o.email + ``).toLocaleLowerCase().includes(search)) ||
          (o.first_name && (o.first_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.last_name && (o.last_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.extra_tracking && (o.extra_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.booth_id && (o.booth_id + ``).toLocaleLowerCase().includes(search))
        ) {
          return true;
        }
        else return false;
      })
    }
  }

  const generateCSV = (orders) => {
    const createCsvRow = (order) => {
      return {
        EVENT_NAME: order.event_name ? order.event_name : `-`,
        LOCATION: `${order.shipping_city}, ${order.shipping_state}`,
        ID: order.id,
        STATUS: order.status,
        TRANSIT_DAYS: order.transit_days,
        EVENT_DATE: order.event_date ? moment.utc(moment(order.event_date)).format(`YYYY-MM-DD`) : `-`,
        RETURN_DATE: order.return_date ? moment.utc(moment(order.return_date)).format(`YYYY-MM-DD`) : `-`,
        BOOTH_ID: order.booth_id,
        INBOUND_TRACKING: order.inbound_tracking,
        OUTBOUND_TRACKING: order.outbound_tracking
      }
    }
    const csvRows = orders.map(order => createCsvRow(order));
    const csvOptions = {
      filename: `BBM_orders_from_${start}_to_${end}`,
      showTitle: true,
      title: `BBM orders from event date ${start} to ${end}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  const goToOrder = (orderId) => {
    props.history.push(`/orders/${orderId}`);
  }

  const getTableActions = (orders) => {
    return [
      { label: `Generate\xa0CSV`, handler: () => generateCSV(orders) },
    ];
  }
  const getRowActions = (order) => {
    return [
      { label: `View\xa0Order\xa0Details`, handler: () => goToOrder(order.id) },
    ];
  }

  return (<>
    <div className={cls.root}>
      <Container maxWidth="lg">
        <Typography className={cls.headTxt}>Events (Orders)</Typography>

        <OrdersFilter
          status={status}
          range={range}
          start={start}
          end={end}
          onStatusChange={setStatus}
          onRangeChange={handleRangeChange}
          onDateChange={handleDateChange}
          onNullsChange={handleNullsChange}
          disablePickers={disablePickers}
        />

        <div className={cls.break} />

        <Subscription subscription={GET_ORDERS(status, nulls)} variables={(status !== 'All' && status !== 'Not Done') ? { status: status, startDate: start, endDate: end } : {startDate: start, endDate: end }} onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve orders: ${err.toString()}`)}>
          {({ loading, error, data }) => {
            if (loading) return <Loading fixed />
            if (error) {
              console.log(`Failed to retrieve orders:`, error);
              return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>ERROR FINDING ORDERS</Typography>
                </div>
              )
            }
            if (data && data.orders && data.orders.length > 0) {
              log && console.log(`Orders for orders index:`, data.orders);
              const filteredOrders = applyFilters(data.orders);

              const headers = [
                { id: `EVENT_NAME`, alignLeft: true, numeric: true, label: `Event Name` },
                { id: `LOCATION`, alignLeft: true, numeric: false, label: `Location` },
                { id: `ID`, alignLeft: true, numeric: true, label: `Order` },
                { id: `STATUS`, alignLeft: true, numeric: true, label: `Status` },
                { id: `TRANSIT_DAYS`, alignLeft: true, numeric: false, label: `Transit Days` },
                { id: `EVENT_DATE`, alignLeft: true, numeric: false, label: `Event Date` },
                { id: `RETURN_DATE`, alignLeft: true, numeric: true, label: `Return Date` },
                { id: `BOOTH_ID`, alignLeft: true, numeric: true, label: `Booth` },
                { id: `INBOUND_TRACKING`, alignLeft: true, numeric: true, label: `Return` },
                { id: `OUTBOUND_TRACKING`, alignLeft: false, numeric: true, label: `Sent` },
              ]
              const rows = filteredOrders.map(order => {
                return {
                  EVENT_NAME: order.event_name ? order.event_name : `-`,
                  LOCATION: `${titleCase(order.shipping_city || '')}, ${order.shipping_state || ''}`,
                  ID: order.id,
                  STATUS: order.status,
                  TRANSIT_DAYS: order.transit_days,
                  EVENT_DATE: order.event_date ? moment.utc(moment(order.event_date)).format(`YYYY-MM-DD`) : `-`,
                  RETURN_DATE: order.return_date ? moment.utc(moment(order.return_date)).format(`YYYY-MM-DD`) : `-`,
                  BOOTH_ID: order.booth_id,
                  INBOUND_TRACKING: (order.inbound_tracking!==null) ? order.inbound_tracking.substr(order.inbound_tracking.length - 4) : '-',
                  OUTBOUND_TRACKING: (order.outbound_tracking!==null) ? order.outbound_tracking.substr(order.outbound_tracking.length - 4) : '-',
                  order: order
                }
              })

              return (<>
                <AccordianTable
                  title={`${rows.length} Orders`}
                  headers={headers}
                  rows={rows}
                  actions={getTableActions(data.orders)}
                  search={search}
                  defaultOrder={defaultOrder}
                  defaultOrderBy={defaultOrderBy}
                  order={order}
                  orderBy={orderBy}
                  tablePage={tablePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  setSearch={setSearch}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  setTablePage={setTablePage}
                  setRowsPerPage={setRowsPerPage}
                  setExpandedRowId={setExpandedRowId}
                  className={cls.table}
                >
                  {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                    .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <AccordianRow
                        key={`order-index-${row.order.id}`}
                        rowId={row.order.id}
                        expandedRowId={expandedRowId}
                        setExpandedRowId={setExpandedRowId}
                        columns={[
                          { align: 'left', value: row.EVENT_NAME },
                          { align: 'left', value: row.LOCATION },
                          { align: 'left', value: row.ID },
                          { align: 'left', value: row.STATUS },
                          { align: 'left', value: row.TRANSIT_DAYS },
                          { align: 'left', value: row.EVENT_DATE },
                          { align: 'left', value: row.RETURN_DATE },
                          { align: 'left', value: row.BOOTH_ID },
                          { align: 'left', value: row.INBOUND_TRACKING },
                          { align: 'right', value: row.OUTBOUND_TRACKING },
                        ]}
                        actions={getRowActions(row.order)}
                        onClick={() => goToOrder(row.ID)}
                        className={!row.order.disputed ? cls.row : cls.rowDisputed}
                      >
                        <div />
                      </AccordianRow>
                    ))}
                </AccordianTable>
              </>)
            }
            else return (
              <div className={cls.notFound}>
                <Typography className={cls.notFoundTxt}>NO ORDERS FOUND</Typography>
              </div>
            )
          }}
        </Subscription>

      </Container>
    </div>
  </>)
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

const GET_ORDERS = (status, nulls) => {
  console.log(`GET_ORDER(${status}, ${nulls})`)
  return gql`
query ordersByDate($startDate: date!, $endDate: date!${(status !== 'All' && status !== 'Not Done') ? ', $status: String' : ''}) {
  orders(
    order_by: {event_date: asc},
    where: {
      active: {_eq: true}, 
      _or: [
        {event_date: 
          {_gte: $startDate, _lte: $endDate}
        }
        ${(nulls) ? `, {event_date: {_is_null: true}}` : ``}
      ]
      ${status === 'All' ? '' : ''}
      ${(status === 'Not Done') ? `, status: {_nin: ["Done", "Canceled"]}` : ''}
      ${(status !== 'All' && status !== 'Not Done') ? ', status: {_eq: $status}' : ''}
    }
  ) {
      ...Order
    }
  }
  ${fragments.order}
`;
}

export default withRouter(Orders);