import React, { useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  Button,
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { InsertInvitation } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { GlobalContext } from '../../../global-context';
import moment from 'moment';
import axios from 'axios';

let log = false;

const getDate = date => {
  return new Date(date).toLocaleDateString([], { timeZone: 'UTC' });
};

export default function DateChangeModal({ open, setOpen, order }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const [loading, setLoading] = useState(false);
  const [transitLoading, setTransitLoading] = useState(false);
  const [displayTransitTimes, setDisplayTransitTimes] = useState(false);
  const [serviceArr, setServiceArr] = useState([]);
  const [transitDays, setTransitDays] = useState(order.transit_days);
  const [shipDate, setShipDate] = useState(getDate(order.ship_date));
  const [arrivalDate, setArrivalDate] = useState(getDate(order.arrival_date));
  const [returnShipDate, setReturnShipDate] = useState(getDate(order.return_ship_date));
  const [returnDate, setReturnDate] = useState(getDate(order.return_date));

  const handleShipDateChange = event => {
    console.log(`[handleShipDateChange] Ship date changed: ${event}`);
    const newShipDate = moment(event).format('YYYY-MM-DD');
    console.log('Ship date changed: ', newShipDate);
    setShipDate(getDate(newShipDate));
    order.ship_date = newShipDate;
  };

  const handleArrivalDateChange = event => {
    const newArrivalDate = moment(event).format('YYYY-MM-DD');
    console.log('Arrival date changed: ', newArrivalDate);
    setArrivalDate(getDate(newArrivalDate));
    order.arrival_date = newArrivalDate;
  };

  const handleReturnDateChange = event => {
    const newReturnDate = moment(event).format('YYYY-MM-DD');
    console.log('Return date changed: ', newReturnDate);
    setReturnDate(getDate(newReturnDate));
    order.return_date = newReturnDate;
  };

  const handleReturnShipDateChange = event => {
    const newReturnShipDate = moment(event).format('YYYY-MM-DD');
    console.log('Return shipping date changed: ', newReturnShipDate);
    setReturnShipDate(getDate(newReturnShipDate));
    order.return_ship_date = newReturnShipDate;
  };

  const handleTransitDaysChange = event => {
    const newTransitDays = event.target.value;
    console.log('Transit days changed: ', newTransitDays);
    setTransitDays(newTransitDays);
    order.transit_days = newTransitDays;
  };

  const checkTransitTimes = async () => {
    setTransitLoading(true);
    try {
      if (!displayTransitTimes) {
        if (
          !order.ship_date ||
          order.ship_date === '' ||
          order.ship_date === 'Invalid date' ||
          new Date(order.ship_date) < new Date()
        ) {
          console.log('ship date is invalid or in the past. Using today...');
          handleShipDateChange(new Date());
        }
        const res = await axios.post('/.netlify/functions/check-transit-times', { order });
        const transitServiceSummary = res.data.emsResponse.services;
        console.log('data from axios call', transitServiceSummary);
        transitServiceSummary.map((element, id) => {
          setServiceArr(() => {
            serviceArr.push(element);
            return serviceArr;
          });
          return null;
        });
      } else {
        setServiceArr([]);
      }
      setDisplayTransitTimes(!displayTransitTimes);
    } catch (err) {
      console.log('axios err', err);
    }
    setTransitLoading(false);
  };

  const handleSaveAndClose = async () => {
    await handleSave();
    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const datesUpdated = await ctx.BoothByMail.orders.updateShippingDates(
        order.id,
        moment(shipDate),
        moment(arrivalDate),
        moment(returnShipDate),
        moment(returnDate),
        transitDays
      );

      console.log(`Done saving dates. Updating order #${order.id} with the following:`);

      console.log(`  Transit days:        ${transitDays}`);
      console.log(`  Ship date:           ${shipDate}`);
      console.log(`  Arrival date:        ${arrivalDate}`);
      console.log(`  Event date:          ${order.event_date}`);
      console.log(`  Return ship date:    ${returnShipDate}`);
      console.log(`  Return arrival date: ${returnDate}`);

      await ctx.BoothByMail.logs.writeLog(
        `update-shipping-dates transit_days: ${transitDays}, ship_date: ${shipDate}, arrival_date: ${arrivalDate}, event_date: ${order.event_date}, return_ship_date: ${returnShipDate}, return_date: ${returnDate}`,
        'server',
        null,
        null,
        order.id
      );

      if (!datesUpdated) {
        console.log(`Failed to update the shipping dates on order ${order.id}`);
      }
    } catch (err) {
      throw err;
    }
    setLoading(false);
  };

  //forgot if this func is supposed to save the new dates... going to implement as it makes sense.
  const handleDateCalculation = async () => {
    setLoading(true);
    console.log('the order being calculated', order);
    try {
      //SDK is not set up for test environment causing errors and bugged responses
      const shippingDates = await ctx.BoothByMail.dates.calculateShippingDates(order);

      if (!shippingDates) {
        alert(`Failed to calculate the shipping dates on order ${order.id}.\n\nCheck console for details`);
        setLoading(false);
        return;
      }

      console.log('the shipping dates', shippingDates);

      const { transit_days, ship_date, arrival_date, return_ship_date, return_date } = shippingDates;
      console.log(`Done calculating dates. Updating order #${order.id} with the following:`);

      console.log(`  Transit days:        ${transit_days}`);
      console.log(`  Ship date:           ${ship_date.format('YYYY-MM-DD')}`);
      console.log(`  Arrival date:        ${arrival_date.format('YYYY-MM-DD')}`);
      console.log(`  Event date:          ${order.event_date}`);
      console.log(`  Return ship date:    ${return_ship_date.format('YYYY-MM-DD')}`);
      console.log(`  Return arrival date: ${return_date.format('YYYY-MM-DD')}`);
      order.transit_days = transit_days;
      setTransitDays(transit_days);
      order.ship_date = ship_date;
      setShipDate(getDate(ship_date));
      order.arrival_date = arrival_date;
      setArrivalDate(getDate(arrival_date));
      order.return_ship_date = return_ship_date;
      setReturnShipDate(getDate(return_ship_date));
      order.return_date = return_date;
      setReturnDate(getDate(return_date));

      await ctx.BoothByMail.logs.writeLog(
        `calculate-shipping-date transit_days: ${transit_days}, ship_date: ${ship_date.format(
          'MM-DD-YYYY'
        )}, arrival_date: ${arrival_date.format('MM-DD-YYYY')}, event_date: ${
          order.event_date
        }, return_ship_date: ${return_ship_date.format('MM-DD-YYYY')}, return_date: ${return_date.format(
          'MM-DD-YYYY'
        )}`,
        'server',
        null,
        null,
        order.id
      );
    } catch (err) {
      ctx.handleNotifications(true, 'error', `Failed to calculate dates: ${err.toString()}`);
    }

    handleSave();
    console.log('auto updated order', order);
    setLoading(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Change Dates</DialogTitle>
      <DialogContent className={cls.loadingPaper}>
        {loading ? (
          <CircularProgress
            style={{
              display: 'block',
              margin: 'auto',
              position: 'absolute',
              left: '0',
              right: '0',
            }}
          />
        ) : (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Event Date: <Typography style={{ fontWeight: 'normal' }}>{order.event_date}</Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DialogContentText color='textPrimary'>
                    Optionally use the button to calculate the rest of the dates from the provided event date. Of course
                    you can make the dates whatever you want to if you know better.
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <Button variant='contained' color='secondary' disabled={loading} onClick={handleDateCalculation}>
                    Calculate the Rest
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label='Transit Days'
                    placeholder='Select a number of days in transit...'
                    variant='outlined'
                    margin='dense'
                    value={transitDays}
                    onChange={handleTransitDaysChange}
                    className={cls.input}
                  >
                    <MenuItem value={1}>1 day</MenuItem>
                    <MenuItem value={2}>2 days</MenuItem>
                    <MenuItem value={3}>3 days</MenuItem>
                    <MenuItem value={4}>4 days</MenuItem>
                    <MenuItem value={5}>5 days</MenuItem>
                    <MenuItem value={6}>6 days</MenuItem>
                    <MenuItem value={7}>7 days</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    fullWidth
                    autoOk
                    orientation='portrait'
                    format='yyyy-MM-dd'
                    label='Ship Date'
                    margin='dense'
                    inputVariant='outlined'
                    value={shipDate.toLocaleString()}
                    onChange={handleShipDateChange}
                    className={cls.date}
                  />
                </Grid>

                <Grid container item xs={12}>
                  {transitLoading ? (
                    <Grid item xs={12} className={cls.circleProgress}>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <Grid item>
                      <Button
                        color={!displayTransitTimes ? 'secondary' : 'primary'}
                        disabled={loading}
                        variant='contained'
                        onClick={checkTransitTimes}
                      >
                        Check Transit Times
                      </Button>
                    </Grid>
                  )}
                </Grid>

                {displayTransitTimes && (
                  <>
                    <Grid container style={{ margin: 10 }}>
                      <Grid container direction='row' justify='space-between'>
                        <Grid item xs={4}>
                          <Typography>Service:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>Days in transit:</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>Expected arrival date:</Typography>
                        </Grid>
                      </Grid>
                      <div className={cls.bigLineBreak} />
                      {serviceArr.map((element, id) => {
                        return (
                          <>
                            <Grid container direction='row' justify='space-between' key={id}>
                              <Grid item xs={4}>
                                <Typography>{element.serviceLevelDescription}</Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>{element.businessTransitDays}</Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>{moment(element.deliveryDate).format('YYYY-MM-DD')}</Typography>
                              </Grid>
                            </Grid>
                            <div className={cls.lineBreak}></div>
                          </>
                        );
                      })}
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <DatePicker
                    fullWidth
                    autoOk
                    orientation='portrait'
                    format='yyyy-MM-dd'
                    label='Arrival Date'
                    margin='dense'
                    inputVariant='outlined'
                    value={arrivalDate}
                    onChange={handleArrivalDateChange}
                    className={cls.date}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    fullWidth
                    autoOk
                    orientation='portrait'
                    format='yyyy-MM-dd'
                    label='Return Ship Date'
                    margin='dense'
                    inputVariant='outlined'
                    value={returnShipDate}
                    onChange={handleReturnShipDateChange}
                    className={cls.date}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    fullWidth
                    autoOk
                    orientation='portrait'
                    format='yyyy-MM-dd'
                    label='Return Arrival Date'
                    margin='dense'
                    inputVariant='outlined'
                    value={returnDate}
                    onChange={handleReturnDateChange}
                    className={cls.date}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <DialogActions>
              <Button
                disabled={loading}
                className={cls.button}
                size='small'
                color='secondary'
                variant='contained'
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={false}
                className={cls.button}
                size='small'
                color='primary'
                variant='contained'
                onClick={handleSaveAndClose}
              >
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  calendarIcon: {
    borderStyle: 'solid',
    borderColor: theme.palette.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
  loadingPaper: {
    minWidth: '250px',
    minHeight: '250px',
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  bigLineBreak: {
    width: '100%',
    height: '4px',
    marginTop: '6px',
    marginBottom: '8px',
    backgroundColor: '#9e9e9e',
  },
  circleProgress: {
    width: '100%',
    margin: '0px 4rem',
  },
}));
