import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { GlobalContext } from '../../global-context';
import boothbymail from '@BoothByMail/boothbymail-sdk';

import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  makeStyles,
  Container,
  Grid,
  Typography,
  IconButton,
  Icon,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Tabs,
  Tab,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';

import Loading from '../utils/Loading';

import fragments from '../utils/graphql/fragments';

import OrderStatusTracker from '../reusable/OrderStatusTracker';
import OrderDetailsInfo from './order/OrderDetailsInfo';
import OrderDetailsInfoEditor from './order/OrderDetailsInfoEditor';
import OrderDetailsLogs from './order/OrderDetailsLogs';
import OrderDetailsSessions from './order/OrderDetailsSessions';
import OrderDetailsShipment from './order/OrderDetailsShipment';
import OrderDetailsNotes from './order/OrderDetailsNotes';
import DateChangeModal from './order/DateChangeModal';
import RegenShipmentModal from './order/RegenShipmentModal';
import OutboundModal from './order/OutboundModal';
import InboundModal from './order/InboundModal';

import PrintableShippingLabel from '../reusable/PrintableShippingLabel';
import OrderDetailsSharing from './order/OrderDetailsSharing';
import OrderDetailsCalendar from './order/OrderDetailsCalendar';

const log = false;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ marginTop: '-10px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function Order(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  //const boothbymail = require('./../utils/boothbymail')
  //console.log('Adding the ApolloClient override to boothbymail SDK: ', ctx.apolloClient, boothbymail.defaults.apollo_client)
  //boothbymail.configure({apollo_client: ctx.apolloClient})
  //console.log('Done: ', ctx.apolloClient, boothbymail.defaults.apollo_client)

  const orderId = props.match.params.id;

  const [editMode, setEditMode] = useState(false);
  const [unsavedOrder, setUnsavedOrder] = useState(null);
  const [actionsOpen, setActionsOpen] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentShippingTab, setCurrentShippingTab] = useState(0);
  const [lastLogoUpload, setLastLogoUpload] = useState(Date.now());
  const [openDateChangeModal, setOpenDateChangeModal] = useState(false);
  const [openOutboundModal, setOpenOutboundModal] = useState(false);
  const [openInboundModal, setOpenInboundModal] = useState(false);
  const [openRegenShipmentModal, setOpenRegenShipmentModal] = useState(false);
  const [regenShipmentType, setRegenShipmentType] = useState('');

  const handleShippingTabChange = (event, newTab) => {
    setCurrentShippingTab(newTab);
  };

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const handleLogoUpload = async files => {
    log && console.log('Handling logo upload: ', files);
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      var reader = new FileReader();
      reader.addEventListener('loadend', async e => {
        try {
          const response = await axios({
            url: '/.netlify/functions/request-upload-url',
            method: 'POST',
            data: {
              fileName: orderId,
              fileType: file.type,
            },
            headers: {
              'content-type': 'application/json',
            },
          });

          const { uploadURL } = response.data;

          log && console.log('Received an upload URL: ', uploadURL);
          log && console.log(`About to uplooad size:${file.size} type:${file.type} to ${uploadURL}`);

          const uploadResponse = await axios.put(uploadURL, file, { headers: { 'Content-Type': file.type } });

          log && console.log('Response from S3 logo upload: ', uploadResponse);

          if (uploadResponse.status >= 200 && uploadResponse.status < 400) {
            log && console.log(`Successful logo upload. Updating the order with the URL...`);
            let logoUrl = '';
            try {
              logoUrl = uploadURL.split('?')[0];
              log && console.log(`Logo URL parsed from the S3 upload request URL: ${logoUrl}`);
              const res = await boothbymail.orders.updateLogo(orderId, logoUrl);
              setLastLogoUpload(Date.now());
              log && console.log('Just updated the order with the logo url: ', res);
              ctx.handleNotifications(true, `success`, `Logo uploaded`);
            } catch (error) {
              console.error(error);
              ctx.handleNotifications(true, `error`, `Failed to upload the logo`);
            }
          } else {
            log && console.log(`Failed to upload the logo`);
            ctx.handleNotifications(true, `error`, `Failed to upload the logo`);
          }
        } catch (error) {
          console.error(`Unknown error getting an upload url: ${error.toString()}`);
          ctx.handleNotifications(true, `error`, `Failed to upload the logo`);
        }
      });
      reader.readAsArrayBuffer(file);
    }
    return false;
  };

  const handleEditMode = () => {
    if (editMode) setEditMode(false);
    else setEditMode(true);
  };

  const printBeforeAndAfter = (order, diff) => {
    let ret = '';
    Object.entries(diff).map(entry => {
      let key = entry[0];
      let val = entry[1];
      let oldVal = order[key];
      if (key === 'print_layout') {
        val = val < 0 || val >= ctx.BoothByMail.printLayouts.length ? `-` : ctx.BoothByMail.printLayouts[val];
        oldVal =
          oldVal < 0 || oldVal >= ctx.BoothByMail.printLayouts.length ? `-` : ctx.BoothByMail.printLayouts[oldVal];
      }
      ret += `- ${key} changed from ${oldVal} to ${val}\n`;
    });
    return ret;
  };

  const handleSaveChanges = async (unsavedOrder, originalOrder) => {
    try {
      let diff = boothbymail.utils.diff(originalOrder, unsavedOrder);
      diff.id = originalOrder.id;
      console.log('Saving changes to order info: ', diff);
      const res = await boothbymail.orders.update(diff);
      if (res) {
        const resOrder = res.data.update_orders.returning[0];
        log && console.log(`>> UPDATED Order:`, resOrder);
        delete diff.id;
        await ctx.BoothByMail.orders.addNotes([
          {
            order_id: originalOrder.id,
            user_id: ctx.userProfile.bbm_user_id,
            type: 'Note',
            body: `Updated order details:
          ${printBeforeAndAfter(originalOrder, diff)}`,
          },
        ]);
        ctx.handleNotifications(true, `success`, `Order #${resOrder.id} was successfully updated.`);
        setEditMode(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(`Failed to update order:`, err);
      ctx.handleNotifications(true, `error`, `Failed to update order: ${err.toString()}`);
    }
  };

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };

  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleCopyToClipboard = str => {
    if (str) {
      navigator.clipboard.writeText(str);
      ctx.handleNotifications(true, `info`, `Copied text to clipboard!`);
    } else {
      ctx.handleNotifications(true, `warning`, `No text was found to copy!`);
    }
  };

  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  const removeComplaintFlag = order => {
    let orderCopy = { ...order };
    setUnsavedOrder(orderCopy);
    setUnsavedOrder(state => {
      state.complaint_pending = false;
      handleSaveChanges(state, order);
      return null;
    });
  };

  const showComplaintBanner = order => {
    if (order?.complaint_pending) {
      return (
        <Grid container direction='column' spacing={2} className={cls.complaintBanner}>
          <Grid item container direction='column'>
            <Grid item>
              <Typography style={{ fontWeight: 500, paddingBottom: '1rem' }} variant='h5'>
                Customer complaint pending!
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                The customer filed a complaint and it is still pending. See the notes and the customer service email
                system for more information.
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {' '}
            <div className={cls.lineBreak} />
          </Grid>
          <Grid item container direction='row'>
            <Grid item>
              <Typography>Once it is resolved,&nbsp;</Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ fontWeight: 500, color: 'rgba(88,73,28,255)', cursor: 'pointer' }}
                onClick={() => {
                  removeComplaintFlag(order);
                }}
              >
                remove the customer complaint flag.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else return null;
  };


  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <IconButton style={{ marginRight: ctx.theme.spacing(1) }} className={cls.iconBtn} onClick={() => goBack()}>
            <Icon>arrow_back</Icon>
          </IconButton>
          <Typography className={cls.head}>Order Details</Typography>

          <Subscription
            subscription={GET_ORDER}
            variables={{ orderId: orderId }}
            onError={err => ctx.handleNotifications(true, `error`, `Failed to retrieve order: ` + err.toString())}
          >
            {({ loading, error, data }) => {
              if (loading) return <Loading fixed />;
              if (error) {
                console.log(`Failed to retrieve order:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING ORDER</Typography>
                  </div>
                );
              }
              if (data && data.orders && data.orders.length > 0) {
                const order = data.orders[0];
                log && console.log(`Order Details:`, order);

                const globalActions = [
                  {
                    label: `Change Dates`,
                    handler: async () => {
                      setOpenDateChangeModal(true);
                    },
                  },
                  {
                    label: `Start Outbound`,
                    handler: async () => {
                      setOpenOutboundModal(true);
                    },
                  },
                  {
                    label: `Start Inbound`,
                    handler: async () => {
                      setOpenInboundModal(true);
                    },
                  },
                  {
                    label: `Regenerate Outbound Shipment`,
                    handler: async () => {
                      setRegenShipmentType('out');
                      setOpenRegenShipmentModal(true);
                    },
                  },
                  {
                    label: `Regenerate Inbound Shipment`,
                    handler: async () => {
                      setRegenShipmentType('in');
                      setOpenRegenShipmentModal(true);
                    },
                  },
                  {
                    label: `Regenerate Extra Shipment`,
                    handler: async () => {
                      setRegenShipmentType('extra');
                      setOpenRegenShipmentModal(true);
                    },
                  },
                ];
                const shipmentActions = [
                  {
                    label: `Check Outbound Shipment Status`,
                    handler: () => {
                      console.log('Checking outbound shipment status ' + order.outbound_tracking);
                    },
                  },
                  {
                    label: `Check Inbound Shipment Status`,
                    handler: () => {
                      console.log('Checking inbound shipment status ' + order.inbound_tracking);
                    },
                  },
                ];
                if (order.extra_tracking) {
                  shipmentActions.push({
                    label: `Check Extra Shipment Status`,
                    handler: () => {
                      console.log('Checking extra shipment status ' + order.extra_tracking);
                    },
                  });
                }

                return (
                  <>
                    <div style={{ float: 'right' }}>
                      {editMode ? (
                        <>
                          <Tooltip placement='top' title={`Click to lock & save your changes`}>
                            <Button className={cls.saveBtn} onClick={() => handleSaveChanges(unsavedOrder, order)}>
                              Save Changes
                            </Button>
                          </Tooltip>
                        </>
                      ) : null}
                      <Tooltip
                        placement='top'
                        title={editMode ? `Click to lock & discard your changes` : `Click to unlock & edit the order`}
                      >
                        <IconButton
                          style={{
                            marginLeft: ctx.theme.spacing(1),
                            color: editMode ? ctx.theme.palette.error.main : ctx.theme.palette.secondary.main,
                          }}
                          className={cls.iconBtn}
                          onClick={() => handleEditMode()}
                        >
                          <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement='top' title={`Actions`}>
                        <IconButton
                          style={{ color: ctx.theme.palette.secondary.main }}
                          className={cls.iconBtn}
                          onClick={handleActionsOpen}
                        >
                          <Icon>settings</Icon>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        keepMounted
                        id={`order-actions-menu`}
                        anchorEl={actionsOpen}
                        open={Boolean(actionsOpen)}
                        onClose={handleActionsClose}
                      >
                        {globalActions.map((action, i) =>
                          !action.hide ? (
                            <MenuItem key={`order-action-${i}`} onClick={() => handleAction(action)}>
                              {action.label || `Action ${i + 1}`}
                            </MenuItem>
                          ) : null
                        )}
                      </Menu>
                    </div>
                    {order && <div style={{ width: '100%' }}>{showComplaintBanner(order)}</div>}
                    <div style={{ width: '100%', height: ctx.theme.spacing(3) }} />
                    <OrderStatusTracker order={order} size='large' />
                    <div style={{ width: '100%', height: ctx.theme.spacing(3) }} />

                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                          Information
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Tooltip placement='top' title='Order ID'>
                          <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                            #{order.id || '-'}
                            {!order.print_count ? ' - Digital (no printer)' : ''}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <Tooltip placement='top-end' title='Event Date'>
                          <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                            {order.event_date ? moment(order.event_date).format(`YYYY-MM-DD`) : '-'}
                          </Typography>
                        </Tooltip>
                      </Grid>

                      <div className={cls.break} />

                      <Grid
                        item
                        xs={10}
                        onClick={() => {
                          setEditMode(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {editMode ? (
                          <OrderDetailsInfoEditor order={order} onChange={setUnsavedOrder} />
                        ) : (
                          <OrderDetailsInfo order={boothbymail.orders.simplify(order)} />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <OrderDetailsCalendar
                          orderId={order.id}
                          transitDays={order.transit_days}
                          shipDate={order.ship_date}
                          arrivalDate={order.arrival_date}
                          eventDate={order.event_date}
                          returnShipDate={order.return_ship_date}
                          returnArrivalDate={order.return_date}
                        />
                      </Grid>
                    </Grid>

                    <div className={cls.bigBreak} />

                    <Paper>
                      <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        indicatorColor='primary'
                        textColor='primary'
                        variant='fullWidth'
                        centered
                      >
                        <Tab label='Notes' />
                        <Tab label='Logs' />
                        <Tab label='Shipping' />
                        <Tab label='Logo' />
                        <Tab label='Sharing' />
                        <Tab label='Sessions' />
                      </Tabs>
                    </Paper>

                    <div style={{ height: ctx.theme.spacing(1) }} />

                    {/* NOTES TAB */}
                    <TabPanel value={currentTab} index={0}>
                      <OrderDetailsNotes orderId={order.id} notes={order.notes.filter(note => note.type === 'Note')} />
                    </TabPanel>

                    {/* LOGS TAB */}
                    <TabPanel value={currentTab} index={1}>
                      <OrderDetailsLogs orderId={order.id} logs={order.logs} />
                    </TabPanel>

                    {/* SHIPPING TAB */}
                    <TabPanel value={currentTab} index={2}>
                      <Grid container>
                        <Grid item sm={2} xs={12}>
                          <Tabs
                            orientation='vertical'
                            variant='scrollable'
                            value={currentShippingTab}
                            onChange={handleShippingTabChange}
                            className={cls.tabs}
                          >
                            <Tab icon={<Icon>logout</Icon>} label='Outbound' {...a11yProps(0)} />
                            <Tab icon={<Icon>login</Icon>} label='Inbound' {...a11yProps(1)} />
                            {order.extra_tracking && (
                              <Tab icon={<Icon>logout</Icon>} label='Outbound Extra' {...a11yProps(2)} />
                            )}
                          </Tabs>
                        </Grid>
                        <Grid item sm={10} xs={12}>
                          <TabPanel value={currentShippingTab} index={0}>
                            <OrderDetailsShipment
                              title='Outbound'
                              type='out'
                              orderId={order.id}
                              trackingNumber={order.outbound_tracking}
                              shipDate={order.ship_date}
                              background={`#ffffff`}
                              trackingCache={order.outbound_shipment_activity}
                              address={`${order.shipping_address1}${
                                order.shipping_address2 ? `, ${order.shipping_address2}` : ''
                              }, ${order.shipping_city}, ${order.shipping_state} ${order.shipping_zip}`}
                            />
                          </TabPanel>
                          <TabPanel value={currentShippingTab} index={1}>
                            <OrderDetailsShipment
                              title='Inbound'
                              type='in'
                              orderId={order.id}
                              trackingNumber={order.inbound_tracking}
                              shipDate={order.return_ship_date}
                              background={`#f4f4f4`}
                              trackingCache={order.inbound_shipment_activity}
                              address={`3615 Speeks Drive, Midlothian, VA 23112`}
                            />
                          </TabPanel>
                          {order.extra_tracking && (
                            <TabPanel value={currentShippingTab} index={2}>
                              <OrderDetailsShipment
                                title='Outbound Extra'
                                type='extra'
                                orderId={order.id}
                                trackingNumber={order.extra_tracking}
                                shipDate={order.ship_date}
                                background={`#ffffff`}
                                trackingCache={order.extra_shipment_activity}
                                address={`${order.shipping_address1}${
                                  order.shipping_address2 ? `, ${order.shipping_address2}` : ''
                                }, ${order.shipping_city}, ${order.shipping_state} ${order.shipping_state} ${
                                  order.shipping_zip
                                }`}
                              />
                            </TabPanel>
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>

                    {/* LOGO TAB */}
                    <TabPanel value={currentTab} index={3}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                          {order && (
                            <img
                              src={
                                order.custom_logo
                                  ? `${order.logo_url}?${lastLogoUpload}`
                                  : 'http://app.boothbymail.com/assets/img/BBMLogoDefault.jpg'
                              }
                              width='100%'
                            />
                          )}
                        </Grid>

                        <Grid item sm={6} xs={12}>
                          {order && order.custom_logo && (
                            <DropzoneArea
                              //showPreviews={true}
                              useChipsForPreview={true}
                              acceptedFiles={['image/*']}
                              dropzoneText={'Drag and drop an image here or click'}
                              maxFileSize={1000000}
                              uploadMultiple={false}
                              onChange={handleLogoUpload}
                              filesLimit={100}
                              showAlerts={false}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>

                    {/* SHARING TAB */}
                    <TabPanel value={currentTab} index={4}>
                      <OrderDetailsSharing shareSmsRecords={order.sms_shares} shareEmailRecords={order.email_shares} />
                    </TabPanel>

                    {/* SESSIONS TAB */}
                    <TabPanel value={currentTab} index={5}>
                      <OrderDetailsSessions albumKey={order.album_key} sessions={order.sessions} />
                    </TabPanel>

                    {/*

                {order.moveByReturnRideId ? <>
                  <Divide spacer tip="Track attempts at contacting Lyft and view details related to those attempts." actions={lyftActions}>Lyft</Divide>
                  <OrderDetailsLyft order={order} lyft={order.moveByReturnRideId} />
                </> : null}


                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Divide spacer tip="View what the customer is being charged for this order." actions={arActions}>Customer Price</Divide>
                    <OrderDetailsAR order={order} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Divide spacer tip="View what the driver is being payed for this order.">Driver Pay</Divide>
                    <OrderDetailsAP order={order} />
                  </Grid>
                </Grid>

                {order.accountsReceivable && order.appayments && order.appayments.length > 0 && ctx.userProfile["https://hasura.io/jwt/claims"]["x-hasura-default-role"] === `admin` ? <>
                  <Divide spacer tip="View the profits and costs associated with this order.">Admin Breakdown</Divide>
                  <OrderDetailsBreakdown order={order} />
                </> : null}

                <Divide spacer tip="View orders related to this order by email.">Related Orders</Divide>
                <OrderDetailsRelated order={order} />

                */}
                    <DateChangeModal open={openDateChangeModal} setOpen={setOpenDateChangeModal} order={order} />

                    <OutboundModal open={openOutboundModal} setOpen={setOpenOutboundModal} order={order} />

                    <InboundModal open={openInboundModal} setOpen={setOpenInboundModal} order={order} />

                    <RegenShipmentModal
                      open={openRegenShipmentModal}
                      setOpen={setOpenRegenShipmentModal}
                      order_id={order.id}
                      type={regenShipmentType}
                    />
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>NO ORDER FOUND</Typography>
                  </div>
                );
            }}
          </Subscription>
        </Container>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  infoSection: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  iconBtn: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginTop: '-12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-14px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-16px',
    },
  },
  saveBtn: {
    verticalAlign: 'top',
    marginTop: '-6px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  head: {
    verticalAlign: 'top',
    display: 'inline-block',
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '5px',
    marginBottom: '5px',
    background: '#fcedb3',
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  complaintBanner:{
    borderColor:'#fcedb3',
    borderRadius:"8px",
    backgroundColor:'rgba(253,243,209,255)',
    color:'rgba(128,101,31,255)'
  }
}));

const GET_ORDER = gql`
  subscription get_orders($orderId: bigint!) {
    orders(where: { id: { _eq: $orderId } }) {
      ...OrderWithChildren
    }
  }
  ${fragments.orderWithChildren}
`;

const CANCEL_ORDER = gql`
  mutation cancel_order($id: bigint!) {
    update_orders(where: { id: { _eq: $id } }, _set: { status: "Canceled" }) {
      affected_rows
      returning {
        id
        status
      }
    }
  }
`;

export default withRouter(Order);
