import React, { useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Dialog,
  DialogActions,
  Button,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { GlobalContext } from '../../../global-context';
import axios from 'axios';
import boothbymail from '@BoothByMail/boothbymail-sdk';

let log = false;

export default function RegenShipmentModal({ open, setOpen, order_id, type }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [service, setService] = useState('03');

  const handleServiceChange = event => {
    console.log('Service changed: ', event.target.value);
    setService(event.target.value);
  };

  const regenerateShipment = async () => {
    try {
      const res = await axios.post(
        '/.netlify/functions/generate-shipment',
        {
          order_id,
          type,
          service,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      await ctx.BoothByMail.orders.addNotes([
        {
          order_id,
          user_id: ctx.userProfile.bbm_user_id,
          type: 'Note',
          body: `Regenerated ${type !== 'extra' ? `${type}bound` : 'extra'} tracking number as ${res.data.trackingNumber}`,
        },
      ]);
      setOpen(false);
    } catch (error) {
      console.log(`Failed to regenerate shipment:`, error);
      ctx.handleNotifications(true, `error`, `Failed to regenerate shipment: ${error.message}`);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Regenerate Shipping Label</DialogTitle>
      <DialogContent>
        <DialogContentText>Choose the service level below.</DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label='Service'
              placeholder='Select a service level...'
              variant='outlined'
              margin='dense'
              value={service}
              onChange={handleServiceChange}
              className={cls.input}
            >
              {Object.keys(boothbymail.ups.UPS_SERVICES).map((key, index) => {
                return (
                  <MenuItem key={index} value={boothbymail.ups.UPS_SERVICES[key]}>
                    {key}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => regenerateShipment()}>
          Generate
        </Button>
        <Button color='secondary' onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
}));
