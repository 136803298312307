import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, Typography, CardMedia, CardActionArea, Tooltip, Icon, IconButton } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const ShowcaseCard = React.memo(function ShowcaseCard({photos = [], message, strip, title = '', caption = '', ...props}) {
  const cls = useStyles();

  const [isOpen, setIsOpen] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [lightboxPhotos, setLightboxPhotos] = useState([])

  useEffect(() => {
    if (!photos) return
    let tempList = photos.map(photo => photo && photo.full_url)
    if (strip) {
      tempList.unshift(strip.full_url)
    }
    setLightboxPhotos(tempList)
  }, [photos])

  const showLightbox = () => {
    setPhotoIndex(0)
    setIsOpen(true)
  }

  const toggleMessage = () => {
    setShowMessage(!showMessage)
  }

  return (
  <>
    {isOpen && (
      <Lightbox
        mainSrc={lightboxPhotos[photoIndex]}
        nextSrc={lightboxPhotos[(photoIndex + 1) % lightboxPhotos.length]}
        prevSrc={lightboxPhotos[(photoIndex + lightboxPhotos.length - 1) % lightboxPhotos.length]}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + lightboxPhotos.length - 1) % lightboxPhotos.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % lightboxPhotos.length)
        }
        reactModalStyle={{
          overlay: {
            zIndex: 1000000
          },
          content: {
            zIndex: 1000000
          }
        }}
      />
    )}
    <Card
    style={{minWidth: '100%'}}
    raised={isHovering}
    onMouseOver={() => {setIsHovering(true)}}
    onMouseOut={() => {setIsHovering(false)}}
    >
      <CardActionArea>
        <CardMedia
          style={{height: 375 }}
          title={title}
          onClick={showLightbox}>
            <div className={cls.container} style={{backgroundColor: 'transparent'}}> 
              <img style={{height: '100%'}} src={photos.length > 0 ? photos[0].full_url : strip && strip.full_url} />
              <div className={cls.whiteWash}> 
                <img className={cls.message} src={message && showMessage ? message.full_url : ''} />
              </div> 
                <span className={cls.title}>{title}</span>
                <span className={cls.subtitle}>Click to view images</span>
            </div> 
        </CardMedia>
      </CardActionArea>
      <CardActions>
          <IconButton aria-label="Photos" onClick={showLightbox}>
              <Icon>{`filter${photos.length ? `_${photos.length}` : ''}`}</Icon>
          </IconButton>
          {strip && false && 
            <IconButton aria-label="Strip" style={{marginLeft: '5px'}} onClick={showLightbox}>
                <Icon>view_quilt</Icon>
                <Typography fontSize={10} fontWeight={400} style={{marginLeft: '5px'}}>strip</Typography>
            </IconButton>
          }
          {message && 
            <IconButton aria-label="Message" style={{marginLeft: '5px'}} onClick={toggleMessage}>
                <Icon>gesture</Icon>
                <Typography fontSize={10} fontWeight={400} style={{marginLeft: '5px'}}>show msg</Typography>
            </IconButton>
          }
      </CardActions>
    </Card>
  </>
  );
});

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  container: { 
    width: '100%',
    height: '475px',
    position: 'relative',
    textAlign: 'center',
  },
  whiteWash: { 
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,.85) 100%)',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  message: { 
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    paddingLeft: 20,
    paddingRight: 20,
    bottom: 100,
    left: 0,
    opacity: 0.85
  },
  title: { 
    width: '100%',
    textAlign: 'left',
    position: 'absolute',
    fontSize: 14,
    fontWeight: 600,
    top: 20,
    left: 20
  } ,
  subtitle: { 
    width: '100%',
    textAlign: 'left',
    position: 'absolute',
    fontSize: 10,
    top: 40,
    left: 20
  } 
}));

export default ShowcaseCard