import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';
import { makeStyles, Container, Typography, Button, Grid, Chip } from '@material-ui/core';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import Loading from '../utils/Loading';
import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';
import { ExportToCsv } from 'export-to-csv';
import fragments from '../utils/graphql/fragments';
import axios from 'axios';

const titleCase = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      if (word.length > 0) {
        return word.replace(word[0], word[0].toUpperCase());
      } else {
        return '';
      }
    })
    .join(' ');
};

const defaultOrder = `asc`;
const defaultOrderBy = `EVENT_DATE`;

function OutboundReport(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const applyFilters = data => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.event_name && o.event_name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address1 && (o.shipping_address1 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address2 && (o.shipping_address2 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_city && (o.shipping_city + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_state && (o.shipping_state + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_zip && (o.shipping_zip + ``).toLocaleLowerCase().includes(search)) ||
          (o.outbound_tracking && (o.outbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.inbound_tracking && (o.inbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.email && (o.email + ``).toLocaleLowerCase().includes(search)) ||
          (o.first_name && (o.first_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.last_name && (o.last_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.extra_tracking && (o.extra_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.booth_id && (o.booth_id + ``).toLocaleLowerCase().includes(search))
        ) {
          return true;
        } else return false;
      });
    }
  };

  const generateCSV = orders => {
    const createCsvRow = order => {
      return {
        EVENT_NAME: order.event_name ? order.event_name : `-`,
        LOCATION: `${order.shipping_city}, ${order.shipping_state}`,
        ID: order.id,
        STATUS: order.status,
        TRANSIT_DAYS: order.transit_days,
        EVENT_DATE: order.event_date ? moment.utc(moment(order.event_date)).format(`YYYY-MM-DD`) : `-`,
        RETURN_DATE: order.return_date ? moment.utc(moment(order.return_date)).format(`YYYY-MM-DD`) : `-`,
        BOOTH_ID: order.booth_id,
        INBOUND_TRACKING: order.inbound_tracking,
        OUTBOUND_TRACKING: order.outbound_tracking,
      };
    };
    const csvRows = orders.map(order => createCsvRow(order));
    const csvOptions = {
      filename: `BBM_orders_en_route`,
      showTitle: true,
      title: `BBM orders en route`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const goToOrder = orderId => {
    props.history.push(`/orders/${orderId}`);
  };

  const getTableActions = orders => {
    return [{ label: `Generate\xa0CSV`, handler: () => generateCSV(orders) }];
  };
  const getRowActions = order => {
    return [{ label: `View\xa0Order\xa0Details`, handler: () => goToOrder(order.id) }];
  };

  const ShipmentTrendChip = ({ shipmentAnalysis }) => {
    return shipmentAnalysis ? (
      <Chip
        label={
          shipmentAnalysis && shipmentAnalysis.alreadyLate
            ? 'Late'
            : shipmentAnalysis && shipmentAnalysis.atRisk
            ? 'At Risk'
            : shipmentAnalysis && shipmentAnalysis.latestStatus
        }
        size='small'
        style={{
          backgroundColor:
            shipmentAnalysis && shipmentAnalysis.alreadyLate
              ? 'red'
              : shipmentAnalysis && shipmentAnalysis.atRisk
              ? 'yellow'
              : null,
        }}
      ></Chip>
    ) : null;
  };

  const checkUps = async () => {
    try {
      const res = await axios.post(`.netlify/functions/update-all-shipment-tracking`);
      console.log('the response', res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Typography className={cls.headTxt}>Outbound Report</Typography>

          <Subscription
            subscription={GET_ORDERS()}
            onError={err => ctx.handleNotifications(true, `error`, `Failed to retrieve orders: ${err.toString()}`)}
          >
            {/*  @ts-ignore */}
            {({ loading, error, data }) => {
              if (loading) return <Loading fixed />;
              if (error) {
                console.log(`Failed to retrieve orders:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>ERROR FINDING ORDERS</Typography>
                  </div>
                );
              }
              if (data && data.orders && data.orders.length > 0) {
                const filteredOrders = applyFilters(data.orders);
                const headers = [
                  { id: `ID`, align: 'center', numeric: true, label: `#` },
                  { id: `EVENT_NAME`, align: 'center', numeric: false, label: `Name` },
                  { id: `STATUS`, align: 'center', numeric: false, label: `Status` },
                  { id: `SHIP_DATE`, align: 'center', numeric: false, label: `Ship Date` },
                  { id: `ARRIVAL_DATE`, align: 'center', numeric: false, label: `Arrival Date` },
                  { id: `EVENT_DATE`, align: 'center', numeric: false, label: `Event Date` },
                  { id: `TRENDING`, align: 'center', numeric: false, label: `Trending` },
                  { id: `OUTBOUND_SHIPMENT_STATUS`, align: 'left', numeric: false, label: `Outbound Shipment Status` },
                ];
                const rows = filteredOrders.map(order => {
                  let plannedShipDate;
                  let latestStatus;
                  let scheduledArrivalDate;
                  let lastActivityDate;
                  let lastLocation;
                  let lastLocationDescription;
                  let statusDescription;
                  let lastChecked;
                  const outboundShipmentAnalysis = ctx.BoothByMail.ups.shipmentAnalysis(
                    order,
                    'outbound_shipment_activity'
                  );

                  console.log(
                    order.id,
                    order,
                    outboundShipmentAnalysis,
                    order.outbound_shipment_activity,
                    order.outbound_shipment_activity
                      ? order.outbound_shipment_activity.json
                        ? JSON.parse(order.outbound_shipment_activity.json)
                        : `no json`
                      : `-`
                  );

                  try {
                    lastChecked = order.outbound_shipment_activity.updatedat ?? null;
                    if (lastChecked) {
                      lastChecked = moment.utc(lastChecked).fromNow();
                    } else {
                      lastChecked = 'bad data';
                    }

                    if (
                      ['SDD', 'RDD'].includes(
                        order?.inbound_shipment_activity?.jsonb?.shipment[0]?.package[0]?.deliveryDate[0]?.type
                      )
                    ) {
                      scheduledArrivalDate = moment.utc(scheduledArrivalDate).format('YYYY-MM-DD');
                    } else {
                      scheduledArrivalDate = null;
                    }

                    plannedShipDate =
                      order?.outbound_shipment_activity?.jsonb?.shipment[0]?.package[0]?.pickupDate[0]?.date ?? null;
                    if (plannedShipDate) {
                      plannedShipDate = moment.utc(moment(plannedShipDate)).format(`YYYY-MM-DD`);
                    } else {
                      plannedShipDate = '-';
                    }

                    lastActivityDate = outboundShipmentAnalysis?.latestActivity?.Date ?? null;
                    if (lastActivityDate) {
                      lastActivityDate = moment.utc(moment(lastActivityDate)).format(`YYYY-MM-DD`);
                    } else {
                      lastActivityDate = '-';
                    }

                    latestStatus = outboundShipmentAnalysis?.latestStatus ?? `-`;
                    statusDescription = outboundShipmentAnalysis?.latestActivity?.status?.description ?? null;

                    lastLocation = outboundShipmentAnalysis?.latestActivity.location.address.city
                      ? outboundShipmentAnalysis?.latestLocation
                      : null;
                    lastLocationDescription =
                      outboundShipmentAnalysis?.latestActivity?.ActivityLocation?.Description ?? '-';
                  } catch (e) {
                    console.log(e);
                  }

                  return {
                    ID: (
                      <Typography style={{ fontWeight: '500' }} color='primary'>
                        {order.id}
                      </Typography>
                    ),
                    EVENT_NAME: order.event_name ? (
                      <Grid container style={{ width: 'max-content' }} direction='column' alignItems='center'>
                        <Typography style={{ fontWeight: '500' }}>{order.event_name}</Typography>
                        <Typography color='primary' style={{ fontSize: '.675rem' }}>{`${titleCase(
                          order.shipping_city
                        )}, ${order.shipping_state}`}</Typography>
                      </Grid>
                    ) : (
                      `-`
                    ),
                    STATUS: (
                      <Chip
                        label={order.status}
                        classes={{ label: cls.label }}
                        style={{
                          backgroundColor: '#28a745',
                          fontWeight: '600',
                          color: 'white',
                          borderRadius: '.25rem',
                          height: 'min-content',
                          padding: '4px',
                        }}
                      />
                    ),
                    SHIP_DATE: order.ship_date ? (
                      <Grid container style={{ width: 'max-content' }} direction='column'>
                        <Grid item>
                          <Typography variant='h6' style={{ fontWeight: 400 }}>
                            {moment.utc(moment(order.ship_date)).format(`YYYY-MM-DD`)}
                          </Typography>
                        </Grid>
                        <Grid container direction='row'>
                          <Grid item>
                            <Typography style={{ fontWeight: '700', fontSize: '80%' }} variant='caption'>
                              Planned:&nbsp;
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='caption' style={{ fontSize: '75%', fontWeight: '500' }}>
                              {' '}
                              {plannedShipDate}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      `-`
                    ),
                    ARRIVAL_DATE: order.arrival_date ? (
                      <Grid container direction='column' spacing={1} style={{ width: 'max-content' }}>
                        <Grid item>
                          <Typography variant='h6' style={{ fontWeight: 400 }}>
                            {latestStatus}
                          </Typography>
                        </Grid>
                        <Grid container spacing={1} item direction='row'>
                          <Grid item>
                            <Typography style={{ fontWeight: '700' }} variant='caption'>
                              Planned:&nbsp;
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='caption'> {order.arrival_date}</Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item>
                            <Typography style={{ fontWeight: '700' }} variant='caption'>
                              Scheduled:&nbsp;
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='caption'>{scheduledArrivalDate}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      `-`
                    ),
                    EVENT_DATE: order.event_date ? (
                      <Typography variant='h6' style={{ fontWeight: 400, width: 'max-content' }}>
                        {moment.utc(moment(order.event_date)).format(`YYYY-MM-DD`)}
                      </Typography>
                    ) : (
                      `-`
                    ),
                    TRENDING: !order.outbound_shipment_activity && (
                      <ShipmentTrendChip shipmentAnalysis={outboundShipmentAnalysis} />
                    ),
                    OUTBOUND_SHIPMENT_STATUS: order.outbound_shipment_activity ? (
                      <Grid container style={{ height: '1.75rem' }}>
                        <Grid item container style={{ height: 'inherit', width: 'max-content' }}>
                          <Grid
                            container
                            item
                            style={{
                              height: 'inherit',
                              border: '1px solid grey',
                              borderRight: 0,
                              borderRadius: '.25rem 0 0 .25rem ',
                              padding: '0 5px',
                              width: 'max-content',
                            }}
                          >
                            <Grid item style={{ height: 'inherit', width: 'max-content' }}>
                              {/* what happened and where */}
                              <Typography
                                color='textPrimary'
                                style={{ display: 'flex', height: 'inherit', fontSize: '.75rem', alignItems: 'center' }}
                              >
                                {' '}
                                {statusDescription} at {lastLocationDescription} on&nbsp;
                              </Typography>
                            </Grid>
                            <Grid item style={{ height: 'inherit', width: 'max-content' }}>
                              {/* when it happened */}
                              <Typography
                                color='textPrimary'
                                style={{
                                  display: 'flex',
                                  height: 'inherit',
                                  fontWeight: '600',
                                  fontSize: '.75rem',
                                  alignItems: 'center',
                                }}
                              >
                                {outboundShipmentAnalysis
                                  ? outboundShipmentAnalysis.latestActivity
                                    ? moment
                                        .utc(moment(outboundShipmentAnalysis.latestActivity.Date))
                                        .format(`YYYY-MM-DD`)
                                    : `empty outbound shipment analysis`
                                  : `-`}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              height: 'inherit',
                              backgroundColor: '#f0c758',
                              color: '#c9ced3b',
                              padding: '0 5px',
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: '.75rem',
                                display: 'flex',
                                alignItems: 'center',
                                height: 'inherit',
                                fontWeight: 500,
                              }}
                            >
                              {lastLocation}
                            </Typography>
                          </Grid>
                          <Grid item style={{ height: 'inherit' }}>
                            <Button
                              onClick={e => {
                                e.stopPropagation();
                                checkUps();
                              }}
                              size='small'
                              style={{
                                backgroundColor: '#17a2b8',
                                height: 'inherit',
                                borderRadius: '0 .25rem  .25rem 0',
                                color: '#fff',
                                fontSize: '75%',
                              }}
                            >
                              Refresh
                            </Button>
                          </Grid>
                        </Grid>
                        <Typography color='primary'>
                          Last checked UPS on{' '}
                          {moment.utc(moment(outboundShipmentAnalysis.latestActivity.Date)).format(`MMM YYYY`)}
                        </Typography>
                      </Grid>
                    ) : (
                      '-'
                    ),
                    order: order,
                  };
                });

                return (
                  <>
                    <AccordianTable
                      title={`${rows.length} Outbound Orders`}
                      headers={headers}
                      rows={rows}
                      actions={getTableActions(data.orders)}
                      search={search}
                      defaultOrder={defaultOrder}
                      defaultOrderBy={defaultOrderBy}
                      order={order}
                      orderBy={orderBy}
                      tablePage={tablePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      setSearch={setSearch}
                      setOrder={setOrder}
                      setOrderBy={setOrderBy}
                      setTablePage={setTablePage}
                      setRowsPerPage={setRowsPerPage}
                      setExpandedRowId={setExpandedRowId}
                      className={cls.table}
                    >
                      {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                        .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                        .map(row => {
                          return (
                            <AccordianRow
                              key={`order-index-${row.order.id}`}
                              rowId={row.order.id}
                              expandedRowId={expandedRowId}
                              setExpandedRowId={setExpandedRowId}
                              columns={[
                                { align: 'left', value: row.ID },
                                { align: 'left', value: row.EVENT_NAME },
                                { align: 'left', value: row.STATUS },
                                { align: 'left', value: row.SHIP_DATE },
                                { align: 'left', value: row.ARRIVAL_DATE },
                                { align: 'left', value: row.EVENT_DATE },
                                { align: 'left', value: row.TRENDING },
                                { align: 'left', value: row.OUTBOUND_SHIPMENT_STATUS },
                              ]}
                              actions={getRowActions(row.order)}
                              onClick={() => goToOrder(row.order.id)}
                              className={!row.order.disputed ? cls.row : cls.rowDisputed}
                            >
                              <div />
                            </AccordianRow>
                          );
                        })}
                    </AccordianTable>
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>NO ORDERS FOUND</Typography>
                  </div>
                );
            }}
          </Subscription>
        </Container>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  label: {
    padding: 0,
  },
}));

const GET_ORDERS = () => gql`
  subscription enRouteOrders {
    orders(order_by: { arrival_date: asc }, where: { active: { _eq: true }, status: { _in: ["Outbound", "Claim"] } }) {
      active
      id
      status
      event_name
      first_name
      last_name
      ship_date
      arrival_date
      event_date
      return_ship_date
      return_date
      outbound_tracking
      shipping_address1
      shipping_address2
      shipping_city
      shipping_state
      shipping_zip
      transit_days
      outbound_shipment_activity {
        ...ShipmentActivity
      }
    }
  }
  ${fragments.shipmentActivity}
`;

export default withRouter(OutboundReport);
