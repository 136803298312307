import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { palette, spacing, typography } from '@material-ui/system';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import 'typeface-roboto';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  CssBaseline,
  AppBar,
  SwipeableDrawer,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
  Icon,
} from '@material-ui/core';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import WatchLater from '@material-ui/icons/WatchLater';
import TabletMac from '@material-ui/icons/TabletMac';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { GlobalContext } from '../global-context';
import Routes from '../Routes';
import Searchbar from './Searchbar';
import IconBadgeSubscription from './reusable/IconBadgeSubscription';
import './../index.css';
import NotificationPopup from './reusable/NotificationPopup';

const version = require('../version');

let log = false;

const Box = styled.div`
  ${palette}${spacing}${typography}
`;
const drawerWidth = 320;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  avatar: {
    // margin: 10,
    display: 'inline-flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  branch: {
    padding: theme.spacing(1),
  },
  branchName: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  branchTag: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: '64px',
    backgroundColor: '#242830',
    boxShadow: '0px 3px 8px -1px #20202850',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // overflow: 'hidden', // this was causing the drawer to not scroll
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowY: 'scroll',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showNotification: false,
      notificationVariant: '',
      notificationMessage: '',
      showRegionModal: false,
      version: '',
      branch: '',
      invalidCount: 0,
      enRouteCount: 0,
      liveCount: 0,
      returningCount: 0,
    };
  }

  handleInvalidCountChange = count => {
    this.setState({ invalidCount: count });
  };

  handleEnRouteCountChange = count => {
    this.setState({ enRouteCount: count });
  };

  handleLiveCountChange = count => {
    this.setState({ liveCount: count });
  };

  handleReturningCountChange = count => {
    this.setState({ returningCount: count });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    if (log) {
      console.log('close drawer');
    }
    this.setState({ open: false });
  };

  componentDidMount = () => {
    if (log) {
      console.log(this.context);
    }
    // checks if user has already logged in
    if (!this.context.userIsAuthenticated()) {
      this.context.lock.checkSession({}, (err, authResult) => {
        // handle error or new tokens
        if (err) {
          // Handle error
          console.log('error checking session:', err);
          return;
        }
        this.context.lock.getUserInfo(authResult.accessToken, (error, profile) => {
          if (error) {
            // Handle error
            console.log('error re-authenticating user:', error);
            return;
          }
          if (log) console.log('Re-authenticated!', authResult);
          if (log) console.log('Re-authenticated User Profile: ', profile);
          this.context.setupApollo(authResult.idToken);
          this.context.setUserAuth(profile, authResult.idToken);
        });
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GlobalContext.Consumer>
          {global => {
            // Listening for the authenticated event
            global.lock.on('authenticated', function (authResult) {
              // Use the token in authResult to getUserInfo() and save it to localStorage
              global.lock.getUserInfo(authResult.accessToken, function (error, profile) {
                if (error) {
                  console.log('error authenticating user:', error);
                  return;
                }
                if (log) console.log('Authenticated!', authResult);
                if (log) console.log('User Profile: ', profile);
                global.setupApollo(authResult.idToken);
                global.setUserAuth(profile, authResult.idToken);
              });
            });

            return (
              <div className={classes.root}>
                <NotificationPopup
                  show={this.context.notificationShow}
                  variant={this.context.notificationVariant ? this.context.notificationVariant : 'info'}
                  message={this.context.notificationMessage}
                  handleClose={this.context.handleNotifications}
                />

                <CssBaseline />
                <AppBar
                  position='absolute'
                  className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                >
                  <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                    <IconButton
                      style={{ marginRight: '10px' }}
                      color='inherit'
                      aria-label='Open drawer'
                      onClick={this.handleDrawerOpen}
                      className={classNames(classes.menuButton, this.state.open && classes.menuButtonHidden)}
                    >
                      <Icon>menu</Icon>
                    </IconButton>
                    <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
                      <Link to='/'>
                        <Box ml='0px'>
                          <img
                            style={{ marginTop: '6px' }}
                            alt='bbm-logo'
                            width='150px'
                            hidden={this.state.open}
                            src='https://app.boothbymail.com/assets/img/BoothByMail_logo_monochrome%20[web-white-180].png'
                          />
                        </Box>
                      </Link>
                    </Typography>

                    {global.userIsAuthenticated() && (
                      <>
                        <Searchbar />
                        <div className={classes.sectionDesktop}>
                          <IconBadgeSubscription
                            icon={<Icon>wrong_location</Icon>}
                            tooltip='New orders with something wrong'
                            onQueryError={err =>
                              this.context.handleNotifications(
                                true,
                                'warning',
                                'Invalid orders badge failed to load because of a ' + err.message
                              )
                            }
                            onClick={() => {
                              this.props.history.push(`/orders`);
                            }}
                            gqlQuery={(type = 'query') => gql`
                              ${type} get_all_invalid_orders{
                                  orders(
                                    where: {
                                      _and: 
                                        {
                                        status: {_in: ["Invalid"]}, 
                                        active: {_eq: "1"}
                                      }
                                    }
                                  ) {
                                    id
                                    status
                                    event_name
                                    event_date
                                    arrival_date
                                    return_ship_date
                                    return_date
                                  }
                                }
                              `}
                          />

                          <IconBadgeSubscription
                            icon={<Icon>local_shipping</Icon>}
                            tooltip='Orders en route to the customer'
                            onQueryError={err =>
                              this.context.handleNotifications(
                                true,
                                'warning',
                                'Outbound orders badge failed to load because of a ' + err.message
                              )
                            }
                            onClick={() => {
                              this.props.history.push(`/outbound-report`);
                            }}
                            gqlQuery={(type = 'query') => gql`
                              ${type} get_all_outbound_orders{
                                  orders(
                                    where: {
                                      _and: 
                                        {
                                        status: {_in: ["Outbound"]}, 
                                        active: {_eq: "1"}
                                      }
                                    }
                                  ) {
                                    id
                                    status
                                    event_name
                                    event_date
                                    arrival_date
                                    return_ship_date
                                    return_date
                                  }
                                }
                              `}
                          />

                          <IconBadgeSubscription
                            icon={<Icon>celebration</Icon>}
                            tooltip='Live events going on now'
                            onQueryError={err =>
                              this.context.handleNotifications(
                                true,
                                'warning',
                                'Live orders badge failed to load because of a ' + err.message
                              )
                            }
                            onClick={() => {
                              this.props.history.push(`/event-schedule`);
                            }}
                            gqlQuery={(type = 'query') => gql`
                              ${type} get_all_live_orders{
                                  orders(
                                    where: {
                                      _and: 
                                        {
                                          status: {_in: ["Delivered"]}, 
                                        active: {_eq: "1"}
                                      }
                                    }
                                  ) {
                                    id
                                    status
                                    event_name
                                    event_date
                                    arrival_date
                                    return_ship_date
                                    return_date
                                  }
                                }
                              `}
                          />

                          <IconBadgeSubscription
                            icon={<Icon>undo</Icon>}
                            tooltip='Orders en route back to us'
                            onQueryError={err =>
                              this.context.handleNotifications(
                                true,
                                'warning',
                                'Inbound orders badge failed to load because of a ' + err.message
                              )
                            }
                            onClick={() => {
                              this.props.history.push(`/inbound-report`);
                            }}
                            gqlQuery={(type = 'query') => gql`
                              ${type} get_all_inbound_orders{
                                  orders(
                                    where: {
                                      _and: 
                                      {
                                        active: {_eq: true}, 
                                        status: {_in: ["Outbound", "Delivered", "Packing", "Inbound", "Returned", "Claim"]},
                                        event_date: {_lt: "${new Date().toISOString().substring(0, 10)}"}
                                      }
                                    }
                                  ) {
                                    id
                                    status
                                    event_name
                                    event_date
                                    arrival_date
                                    return_ship_date
                                    return_date
                                  }
                                }
                              `}
                          />

                          <IconButton
                            edge='end'
                            aria-label='account of current user'
                            aria-controls={'primary-search-account-menu'}
                            aria-haspopup='true'
                            color='inherit'
                            onClick={e => {
                              this.setState({ anchorEl: e.currentTarget });
                            }}
                          >
                            <Icon>account_circle</Icon>
                          </IconButton>
                        </div>
                        <Menu
                          getContentAnchorEl={null}
                          anchorEl={this.state.anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          id={'primary-search-account-menu'}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(this.state.anchorEl)}
                          onClose={() => {
                            this.setState({ anchorEl: null });
                          }}
                        >
                          <Link to='/profile'>
                            <MenuItem>Profile</MenuItem>
                          </Link>
                          <MenuItem
                            onClick={() => {
                              global.lock.logout({
                                returnTo:
                                  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                                    ? `https://localhost:8888/login`
                                    : `https://${process.env.REACT_APP_A0_CB_SD}.boothbymail.com`,
                              });
                            }}
                          >
                            Logout
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Toolbar>
                </AppBar>
                <div onClick={this.handleDrawerClose}>
                  <SwipeableDrawer
                    // variant="permanent"
                    classes={{
                      paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}
                    onOpen={this.handleDrawerOpen}
                    onClose={this.handleDrawerClose}
                  >
                    <Divider />

                    <div className={classes.branch}>
                      <Typography className={classes.branchName}>
                        {version.branch !== 'master' && version.branch !== 'HEAD' ? version.branch : ''}
                      </Typography>
                      <Typography className={classes.branchTag}>{version.tag}</Typography>
                    </div>

                    <Divider />

                    {!global.userIsAuthenticated() && (
                      <>
                        <List>
                          <ListItem button component={Link} to='/orders' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>person</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Login' />
                          </ListItem>
                        </List>
                        <Divider />
                      </>
                    )}

                    {global.userIsAuthenticated() && (
                      <>
                        <List>
                          <ListItem button component={Link} to='/' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>dashboard</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Dashboard' />
                          </ListItem>

                          <ListItem button component={Link} to='/orders' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>store</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Orders' />
                          </ListItem>

                          <ListItem button component={Link} to='/booths' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <TabletMac />
                            </ListItemIcon>
                            <ListItemText primary='Booths' />
                          </ListItem>
                        </List>
                        <Divider />
                        <List>
                          <ListSubheader>Schedulers</ListSubheader>

                          <ListItem button component={Link} to='/outbound-schedule' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>event</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Outbound Schedule' />
                          </ListItem>

                          <ListItem button component={Link} to='/inbound-schedule' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>today</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Inbound Schedule' />
                          </ListItem>

                          <ListItem button component={Link} to='/event-schedule' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>celebration</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Event Schedule' />
                          </ListItem>
                        </List>
                        <Divider />
                        <List>
                          <ListSubheader>Reports</ListSubheader>

                          <ListItem button component={Link} to='/outbound-report' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>local_shipping</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Outbound' />
                          </ListItem>

                          <ListItem button component={Link} to='/inbound-report' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <Icon>assignment_return</Icon>
                            </ListItemIcon>
                            <ListItemText primary='Inbound' />
                          </ListItem>

                          <ListItem button component={Link} to='/late-returns-report' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <WatchLater />
                            </ListItemIcon>
                            <ListItemText primary='Late Returns' />
                          </ListItem>

                          <ListItem button component={Link} to='/complaints-report' onClick={this.handleDrawerClose}>
                            <ListItemIcon>
                              <ThumbDownIcon />
                            </ListItemIcon>
                            <ListItemText primary='Complaints' />
                          </ListItem>
                        </List>
                      </>
                    )}
                  </SwipeableDrawer>
                </div>
                <main className={classes.content} ref={ref => (global.mainRef = ref)}>
                  <div style={{ marginTop: '100px' }} id='login' hidden={global.userIsAuthenticated()}></div>
                  <div style={{ width: '100%', height: '64px' }} />
                  <Routes />
                </main>
              </div>
            );
          }}
        </GlobalContext.Consumer>
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

Layout.contextType = GlobalContext;

export default withRouter(withStyles(styles)(Layout));
