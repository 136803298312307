import React, { Component } from "react";
import "./index.css";
import { GlobalContext } from "./global-context";
import Layout from "./components/Layout";
import Auth0Lock from "auth0-lock";
import ApolloClient from "apollo-client"
import { WebSocketLink } from "apollo-link-ws"
import { HttpLink } from "apollo-link-http"
import { split } from "apollo-link"
import { getMainDefinition } from "apollo-utilities"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import { ApolloProvider } from "react-apollo"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { withWidth } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import axios from 'axios'
import BoothByMail from '@BoothByMail/boothbymail-sdk'

let log = false

const theme = createMuiTheme({
  palette: {
    
    // Theme Colors
    primary: { light: `#80c8ff`, main: `#2893ef`, dark: `#186ab4`, contrastText: `#fff` },
    secondary: { light: `#a0a8b0`, main: `#505860`, dark: `#242830`, contrastText: `#fff` },
    warning: { light: `#ffb74d`, main: `#ff9800`, dark: `#f57c00`, contrastText: `#fff` },
    error: { superLight: `#ffeeee`, light: `#ff8872`, main: `#f44436`, dark: `#c82218`, contrastText: `#fff` },
    success: { light: `#81c784`, main: `#4caf50`, dark: `#388e3c`, contrastText: `#fff` },
    info: { light: `#80c8ff`, main: `#2893ef`, dark: `#084887`, contrastText: `#fff` },

    // Text Colors
    text: {
      primary: `#505860`,
      secondary: `#a0a8b0`,
      tertiary: `#808890`,
      disabled: `#c0c8d0`,
      hint: `#c0c8d0`,
    },

    // Custom Colors
    custom: {
      light: `#f8f8f8`,
      medium: `#f0f0f0`,
      dark: `#ddd`,
    },

    // Action Colors
    action: {
      //   active: `#aaa`,
      hover: `#f0f0f0`,
      //   selected: `#f0f0f0`,
      //   disabled: `#bbb`,
      //   disabledBackground: `#ddd`,
    },

    // Background Colors
    background: {
      default: `#fafafa`,
      paper: `#ffffff`,
    },

    // Border/Divider Colors
    // divider: `#ddd`,
  },

  typography: { useNextVariants: true },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsAuthenticated: () => {
        if (this.state.userProfile.email) {
          return true;
        } else return false;
      },
      theme: theme,
      width: props.width,
      notificationShow: false,
      notificationVariant: '',
      notificationMessage: '',
      handleNotifications: async (show, variant = this.state.notificationVariant, message = this.state.notificationMessage) => await this.setState(
        {
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message
        }
      ),
      userProfile: {},
      userToken: "",
      redirectPath: null,
      lock: new Auth0Lock(
        process.env.REACT_APP_AUTH_CLIENT_ID,
        process.env.REACT_APP_AUTH_DOMAIN,
        {
          id: 'login',
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
          allowSignUp: false,
          container: 'login',
          theme: {
            logo: "https://app.boothbymail.com/assets/img/BoothByMail_logo_monochrome%20[web-white-180].png",
            primaryColor: "#3f51b5",
          },
          languageDictionary: {
            title: "",
          },
          auth: {
            redirectUrl: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? `http://localhost:8888/login` : `https://${process.env.REACT_APP_SUBDOMAIN}.boothbymail.com/login`,
            responseType: "code token id_token",
            params: {
              scope: "openid email profile", // Learn about scopes: https://auth0.com/docs/scopes
            },
          },
        }
      ),
      logout: () => {
        if (log) { console.log("running logout function") }
        this.state.lock.logout({ returnTo: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? `http://localhost:8888/login` : `https://${process.env.REACT_APP_A0_CB_SD}.boothbymail.com/login` })
        this.state.userProfile.email = null
      },
      setUserAuth: (profile, token) => {
        if (log) console.log("profile", profile, "and token", token);
        profile.bbm_user_id = profile["https://hasura.io/jwt/claims"]["x-bbm-user-id"]
        this.setState({ userProfile: profile });
        this.setState({ userToken: token });
      },
      apolloClient: {},
      setupApollo: (token) => {
        if (log) { console.log('Setting up the Apollo...') }
        const authLink = setContext(async (_, { headers }) => {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          }
        }),
          wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL,
          httpurl = process.env.REACT_APP_GRAPHQL_URL,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query)
              return (
                kind === "OperationDefinition" && operation === "subscription"
              )
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          })
        if (log) { console.log('Apollo Client Initialized! ', client) }
        this.setState({ apolloClient: client });
        BoothByMail.configure({apollo_client: client})
      },
      BoothByMail: BoothByMail
    };
  }

  updateRegions = () => {
    if (this.state.userToken) axios({
      url: `https://sat-auth-test.auth0.com/api/v2/users/auth0|5d9cb6a761ce8b0e15c5c1a5`,
      method: 'patch',
      data: {
        app_metadata: { 'x-hasura-allowed-regions': "{1,2,3}" }
      },
      headers: {
        authorization: `Bearer ${this.state.userToken}`,
        'content-type': 'application/json'
      }
    })
      .then(res => {
        log && console.log(res)
      })
  }

  render() {
    if (this.state.userIsAuthenticated()) {
      log && console.log('Rendering with ApolloProvider now...');
      log && console.log(this.lock);
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <ApolloProvider client={this.state.apolloClient}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout />
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </GlobalContext.Provider>
        </MuiThemeProvider>
      )
    } else {
      if (log) { console.log('Rendering without ApolloProvider until user is logged in.') }
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <Layout />
          </GlobalContext.Provider>
        </MuiThemeProvider>
      )
    }
  }
}

export default withWidth()(App);
