import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { Tooltip, IconButton, Badge, Icon } from '@material-ui/core';
import { Query } from "react-apollo";

let log = false;

function IconBadgeSubscription(props) {
  const { icon, tooltip, onClick, onQueryError, gqlQuery, onCountChange } = props

  const handleClick = () => {
    log && console.log(`Badge clicked`)
    if (onClick) onClick()
  }

  const handleQueryError = (err) => {
    log && console.log(`Query error: ${err.message}`)
    if (onQueryError) onQueryError(err)
  }

  return (
    <Query
      query={gqlQuery('query')}
      onError={handleQueryError} >
    {({ subscribeToMore, ...result }) => {
      if (result.loading) {
        return (
          <Tooltip title={'Loading badge data...'}>
            <IconButton aria-label="view outbound report" color="inherit" onClick={handleClick}>
              <Badge badgeContent="..." color="error">
                {icon}
              </Badge>
            </IconButton>
          </Tooltip>
        )
      }
      if (result.error) {
        return (
          <Tooltip title={`Error fetching badge data: ${result.error.message}`}>
            <IconButton aria-label="view outbound report" color="inherit" onClick={handleClick}>
              <Badge badgeContent="?" color="error">
                {icon}
              </Badge>
            </IconButton>
          </Tooltip>
        )
      }
      if (result.data) {
        subscribeToMore({
          document: gqlQuery('subscription'),
          updateQuery: (prev, { subscriptionData }) => {
            log && console.log("subscriptionData:", subscriptionData, "prev:", prev);
            if (!subscriptionData.data) return prev;
            return Object.assign({}, prev, subscriptionData.data)
          }
        })
        if (onCountChange && result && result.data && result.data.orders) onCountChange(result.data.orders.length)
        
        return (
          <Tooltip title={tooltip}>
            <IconButton color="inherit" onClick={handleClick}>
              <Badge badgeContent={result && result.data && result.data.orders && result.data.orders.length} color="error">
                {icon}
              </Badge>
            </IconButton>
          </Tooltip>
        )
      }
    }}
  </Query>
  )
}

export default withRouter(IconBadgeSubscription);