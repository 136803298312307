import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';
import { makeStyles, Container, Typography, Tooltip, Icon, Button, Paper, Grid } from '@material-ui/core';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import Loading from '../utils/Loading';
import fragments from '../utils/graphql/fragments';
import WeeklyScheduleWeekday from '../reusable/WeeklyScheduleWeekday'
var util = require('util');

function OutboundSchedule(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [sunday, setSunday] = useState(moment().startOf('week'))

  return (<>
    <Container maxWidth={false} className={cls.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={cls.headTxt}>Outbound Schedule</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={cls.rowTxt} align={"right"}>
              <Tooltip title={`Go back to today`}>
                <Button variant="outlined" color="primary" onClick={() => {setSunday(moment().startOf('week'))}}>
                  Today
                </Button>
              </Tooltip>
            </Typography>
          </Grid>
        
          <Grid item xs={4}>
            <Typography className={cls.rowTxt} align={"left"}>
              <Tooltip title={`${sunday.clone().subtract(1, 'week').format('MMM D')} - ${sunday.clone().subtract(1, 'week').endOf('week').format('MMM D')}`}>
                <Button variant="contained" color="primary" startIcon={<Icon>keyboard_arrow_left</Icon>} onClick={() => {setSunday(sunday.clone().subtract(1,'week'))}}>
                  Last Week
                </Button>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} align={"center"}>
              {sunday.format('MMM D')} - {sunday.clone().endOf('week').format('MMM D')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={cls.rowTxt} align={"right"}>
              <Tooltip title={`${sunday.clone().add(1, 'week').format('MMM D')} - ${sunday.clone().add(1, 'week').endOf('week').format('MMM D')}`}>
                <Button variant="contained" color="primary" endIcon={<Icon>keyboard_arrow_right</Icon>} onClick={() => {setSunday(sunday.clone().add(1,'week'))}}>
                  Next Week
                </Button>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>

        <Subscription subscription={GET_ORDERS()} variables={{ startDate: sunday.format(), endDate: sunday.clone().endOf('week').format() }} onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve orders: ${err.toString()}`)}>
          {({ loading, error, data }) => {
            if (loading) return <Loading fixed />
            if (error) {
              console.log(`Failed to retrieve orders:`, error);
              return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>ERROR FINDING ORDERS</Typography>
                  <Paper variant="outlined">
                      <Typography>{util.inspect(error, {depth: null})}</Typography>
                  </Paper>
                </div>
              )
            }
            return (<>
              <Grid container>
                  <Grid item lg={1} md={1} sm={12} xs={12}>
                    <WeeklyScheduleWeekday sundayMoment={sunday} orders={data.orders.filter(o => o.ship_date === sunday.clone().add(0, 'day').format('YYYY-MM-DD'))}/>
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <WeeklyScheduleWeekday sundayMoment={sunday} offsetDays={1} orders={data.orders.filter(o => o.ship_date === sunday.clone().add(1, 'day').format('YYYY-MM-DD'))}/>
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <WeeklyScheduleWeekday sundayMoment={sunday} offsetDays={2} orders={data.orders.filter(o => o.ship_date === sunday.clone().add(2, 'day').format('YYYY-MM-DD'))}/>
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <WeeklyScheduleWeekday sundayMoment={sunday} offsetDays={3} orders={data.orders.filter(o => o.ship_date === sunday.clone().add(3, 'day').format('YYYY-MM-DD'))}/>
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <WeeklyScheduleWeekday sundayMoment={sunday} offsetDays={4} orders={data.orders.filter(o => o.ship_date === sunday.clone().add(4, 'day').format('YYYY-MM-DD'))} />
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12}>
                    <WeeklyScheduleWeekday sundayMoment={sunday} offsetDays={5} orders={data.orders.filter(o => o.ship_date === sunday.clone().add(5, 'day').format('YYYY-MM-DD'))}/>
                  </Grid>
                  <Grid item lg={1} md={1} sm={12} xs={12}>
                    <WeeklyScheduleWeekday sundayMoment={sunday} offsetDays={6} orders={data.orders.filter(o => o.ship_date === sunday.clone().add(6, 'day').format('YYYY-MM-DD'))}/>
                  </Grid>
              </Grid>
            </>)
          }
        }
        </Subscription>

    </Container>
  </>)
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

const GET_ORDERS = () => (gql`
query getOrdersByShipDateRange($startDate: date!, $endDate: date!) {
  orders(
    order_by: {ship_date: asc},
    where: {
      active: {_eq: true},
      status: {_nin: ["Cancelled", "Canceled"]},
      _or: [
        {ship_date: 
          {_gte: $startDate, _lte: $endDate}
        },
        #{arrival_date: 
        #  {_gte: $startDate, _lte: $endDate}
        #},
        #{event_date: 
        #  {_gte: $startDate, _lte: $endDate}
        #},
        #{return_ship_date: 
        #  {_gte: $startDate, _lte: $endDate}
        #},
        #{return_date: 
        #  {_gte: $startDate, _lte: $endDate}
        #}
      ]
    }
  ) {
      ...Order
    }
  }
  ${fragments.order}
`);

export default withRouter(OutboundSchedule);