import React, { Component } from "react"
import { GlobalContext } from "../../global-context"
import Grid from '@material-ui/core/Grid'

class Login extends Component {
    componentDidMount = () => {
        if (!this.context.userIsAuthenticated()) {
            this.context.lock.show()
        }
    }

    render() {
        return (<>
            <div style={{ width: '100%', height: '32px' }} />
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <div style={{topMargin: "20px"}}><div id="login"></div></div>
            </Grid>
        </>)
    }
}

Login.contextType = GlobalContext;

export default Login;