import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip, Icon } from '@material-ui/core';
import { AccordianTable, AccordianRow } from '../../reusable/AccordianTable';
import ShippingLabel from '../../reusable/ShippingLabel'

const log = true;

function OrderDetailsShipment(props) {
  const cls = useStyles();

  const { title, orderId, type, trackingNumber, shipDate, address, background, trackingCache } = props;
  
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expandedRowId, setExpandedRowId] = useState(0);
  const [trackingActivityRows, setTrackingActivityRows] = useState([]);
  const [parsedTrackingCacheJSON, setParsedTrackingCacheJSON] = useState({});

  const statusTypeCodes = {
    'I': 'In Transit',
    'D': 'Delivered',
    'X': 'Exception',
    'P': 'Pickup',
    'M': 'Manifest Pickup'
  }

  useEffect(() => {
    //Parse the JSON string from the tracking cache
    try {
      if (trackingCache && trackingCache.jsonb) {
        log && console.log('Found JSONB for tracking cache')
        setParsedTrackingCacheJSON(trackingCache.jsonb)
      } else {
        log && console.log(`Parsing the shipment tracking history JSON for tracking #${trackingNumber}`, trackingCache)
        let temp = JSON.parse(trackingCache.json)
        log && console.log('Tracking cache parsed: ', temp)
        setParsedTrackingCacheJSON(temp)
      }
    } catch (error) {
      log && console.log(`Tracking cache JSON string invalid for ${title} shipment ${trackingNumber}. Error: ${error.message}`)
    }
  }, [trackingCache]);
  
  useEffect(() => {
    //Make sure this object tree exists and results in an array first
    try {
      if (parsedTrackingCacheJSON && parsedTrackingCacheJSON.Shipment && parsedTrackingCacheJSON.Shipment.Package) {
        log && console.log(`Rendering shipment details for ${title} tracking #${trackingNumber}`)
        let trackingActivityArr = parsedTrackingCacheJSON.Shipment.Package.Activity
        
        //20210318 Rob - Bug found that if there is only one item in the Activity, then it is 
        // returned as an object without the array. In that scenario the Activity.map will
        // fail saying Activity.map is not a function. To get around this we force Activity
        // to always be a function even if it's just one item or empty.
        if (!Array.isArray(trackingActivityArr)) {
          trackingActivityArr = new Array(trackingActivityArr)
        }
        
        let rows = trackingActivityArr.map(history => {
          return {
            STATUS: history.Status && history.Status.Type ? statusTypeCodes[history.Status.Type] : `-`,
            DATE: history.Date && history.Time ? moment(`${history.Date}T${history.Time}`).format('YYYY-MM-DD') : '-',
            LOCATION: history.ActivityLocation && history.ActivityLocation.Address && history.ActivityLocation.Address.City ? `${history.ActivityLocation.Address.City},\xa0${history.ActivityLocation.Address.StateProvinceCode}` : '-',
            ACTIVITY: history.Status && history.Status.Description ? history.Status.Description : `-`,
            activity: history
          }
        })
        setTrackingActivityRows(rows)
        log && console.log(`${title} tracking #${trackingNumber} activity array has ${rows.length} entries`, rows)
      }
    } catch(e) {
      log && console.log(`Failed to parse tracking activity`, e.message);
    }
  }, [parsedTrackingCacheJSON]);

  const goToUPSTrackingPage = () => {
    window.location.href = `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}&requester=WT/trackdetails`; 
    return null;
  }

  const refreshTracking = () => {
    log && console.log('Refresh tracking')
  }
  
  const useBarStyles = makeStyles(theme => ({
    p1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `33.333%`,
      height: theme.spacing(1),
      background: theme.palette.primary.main,
    },
    p2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `33.333%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.main,
    },
    p3: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `33.333%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.light,
    },
    d1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `33.333%`,
      height: theme.spacing(1),
      background: theme.palette.primary.main,
    },
    d2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `33.333%`,
      height: theme.spacing(1),
      background: theme.palette.secondary.main,
    },
  }))
  const barCls = useBarStyles();

  return (<>
      <Grid>
        <Grid item xs={12} md={12} lg={12}>
          {(trackingActivityRows && trackingActivityRows.length > 0) ?
          <AccordianTable
            title={<>
              <Tooltip placement="top-end" title={`Click to visit the UPS tracking page`}>
                <a className={cls.location} href={`https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}&requester=WT/trackdetails`} target="_new">
                  <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
                    <Grid item xs>
                      <Typography className={cls.headTxtL}>{trackingCache && trackingCache.latest_status_description ? trackingCache.latest_status_description : '-'}</Typography>
                      <Typography className={cls.addressTxt} color='primary'>{trackingNumber} <Icon fontSize="inherit">open_in_new</Icon></Typography>
                    </Grid>
                    <Grid>
                      <ShippingLabel orderId={orderId} type={type} width="80px" rotate trackingNumber={trackingNumber} />
                    </Grid>
                  </Grid>
                </a>
              </Tooltip>
            </>}
            headers={[
              { id: `STATUS`, alignLeft: true, numeric: false, label: `Status` },
              { id: `DATE`, alignLeft: true, numeric: false, label: `Date` },
              { id: `LOCATION`, alignLeft: true, numeric: false, label: `Location` },
              { id: `ACTIVITY`, alignLeft: true, numeric: false, label: `Activity` }
            ]}
            rows={trackingActivityRows}
            actions={[
              { label: `Refresh`, handler: () => refreshTracking() },
            ]}
            tablePage={tablePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setExpandedRowId={setExpandedRowId}
            setTablePage={setTablePage}
            setRowsPerPage={setRowsPerPage}
            className={cls.table}
          >
            {trackingActivityRows
            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
            .map((row, index) => (
                <AccordianRow
                  key={`activity-index-${index+1}`}
                  rowId={index+1}
                  expandedRowId={expandedRowId}
                  setExpandedRowId={setExpandedRowId}
                  columns={[
                    { align: 'left', value: row.STATUS },
                    { align: 'left', value: row.DATE },
                    { align: 'left', value: row.LOCATION },
                    { align: 'left', value: row.ACTIVITY }
                  ]}
                  actions={[]}
                  //onClick={() => goToOrder(row.ID)}
                  className={cls.row}
                >
                  <Grid container spacing={3} justify="space-between" wrap="nowrap">
                    <Grid item sm={12} xs={12}>
                      {row.activity.Status.Description}
                    </Grid>
                  </Grid>
                </AccordianRow>
              ))}
          </AccordianTable>
          : 
          <>
            <div className={cls.notFound}>
              <Typography className={cls.notFoundTxt}>NO ACTIVITY YET</Typography>
              <Tooltip placement="top-end" title={`Click to visit the UPS tracking page`}>
                <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
                  <Grid item xs>
                    <a className={cls.location} href={`https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}&requester=WT/trackdetails`} target="_new">
                      <Typography className={cls.headTxtL}>{trackingCache && trackingCache.latest_status_description ? trackingCache.latest_status_description : '-'}</Typography>
                      <Typography className={cls.addressTxt} color='primary'>{trackingNumber} <Icon fontSize="inherit">open_in_new</Icon></Typography>
                    </a>
                  </Grid>
                  <Grid>
                    <ShippingLabel orderId={orderId} type={type} width="80px" rotate trackingNumber={trackingNumber} />
                  </Grid>
                </Grid>
              </Tooltip>
            </div>
          </>
        }
        </Grid>
      </Grid>

  </>)
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    color: theme.palette.text.secondary,
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  location: {
    borderRadius: '4px',
    backgroundColor: '#00000000',
    "&:hover": {
      backgroundColor: '#00000012',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  nameTxt: {
    lineHeight: 1.25,
    fontSize: '18px',
    fontWeight: 500,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  addressTxt: {
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  timeTxtKey: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  timeTxtVal: {
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  sig: {
    display: 'block',
    position: 'relative',
    height: 'fit-content',
  },
  sigImage: {
    display: 'block',
    position: 'relative',
    maxWidth: '200px',
    maxHeight: '200px',
  },
  sigTxt: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1.5),
    color: '#fff',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  block: {
    display: 'block',
    height: theme.spacing(1.5),
  },
  inline: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  leftTxt: {
    textAlign: 'left',
  },
  midTxt: {
    textAlign: 'center',
  },
  rightTxt: {
    textAlign: 'right',
  },
  ellipse: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

export default withRouter(OrderDetailsShipment);