import React, {useEffect} from 'react';
import Printable from './Printable'
import ShippingLabel from './ShippingLabel'

export default function PrintableShippingLabels({ orders, type, hide, trigger }) {
    return (
        <Printable trigger={trigger} hide={hide}>
            <div>
                {orders && orders.map((order) => {
                    if (type === 'all') {
                        return (<React.Fragment key={`printable-ShippingLabel-${order.id}`}>
                            <ShippingLabel orderId={order.id} type={'out'} /><br />
                            <ShippingLabel orderId={order.id} type={'in'} /><br />
                            {order.print_count > 200 &&
                                <ShippingLabel orderId={order.id} type={'extra'} />
                            }
                        </React.Fragment>)
                    } else {
                        return (<React.Fragment key={`printable-ShippingLabel-${order.id}`}>
                            <ShippingLabel orderId={order.id} type={type} /><br />
                        </React.Fragment>)
                    }
                })}
            </div>
        </Printable>
    );
}