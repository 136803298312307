import React, { Component } from "react";
import PropTypes from 'prop-types';
// import { TextField, Button } from '@material-ui/core';
import { fade, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { GlobalContext } from '../global-context';
import { withRouter } from 'react-router';

const styles = theme => ({
    grid: {
        width: '60%',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      },
      searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: 200,
        },
      },
})

class Searchbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: ''
        }
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    searchOrders = () => {
        const searchString = this.state.search;
        console.log('Searching for ' + searchString)
        this.props.history.push({ pathname: `/orders/searchresults/`, state: { search: searchString } });
    }

    submitSearch = (event) => {
        event.preventDefault();
        this.searchOrders();
        event.target.reset()
    }

    render() {
        const { classes } = this.props;
        return (
            <form
            onSubmit={this.submitSearch}
            >
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        name="search"
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={this.handleInputChange}
                    />
                </div>
            </form>
        )
    }
};

Searchbar.contextType = GlobalContext;

Searchbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Searchbar));