import 'date-fns';
import React, { Component } from "react";
import { GlobalContext } from "../../global-context";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import OrdersTableView from './searchResults/OrdersTableView';
import DateFilter from "../reusable/DateFilter";
import moment from 'moment';
import { Container, Typography, MenuItem, Grid } from "@material-ui/core";
import { Query } from 'react-apollo';
import gql from "graphql-tag";
import fragments from '../utils/graphql/fragments';
import Loading from '../utils/Loading';

let log = false;

const SEARCH_MOVES = gql`
query get_orders_by_fields(
    $searchString: String!
    $searchInt: bigint!
    $searchNumeric: numeric!
) {
    orders(
        order_by: {updatedat: desc}, 
        where: {
          _or: [
            {id: {_eq: $searchInt}}, 
            {booth_id: {_eq: $searchInt}}, 
            {event_name: {_ilike: $searchString}}, 
            {outbound_tracking: {_ilike: $searchString}}, 
            {inbound_tracking: {_ilike: $searchString}}, 
            {extra_tracking: {_ilike: $searchString}},
            {shipping_address1: {_ilike: $searchString}},
            {shipping_address2: {_ilike: $searchString}},
            {shipping_city: {_ilike: $searchString}},
            {shipping_state: {_ilike: $searchString}},
            {shipping_zip: {_ilike: $searchString}},
            {shopify_order_id: {_eq: $searchNumeric}},
            {gallery_url: {_ilike: $searchString}},
            {first_name: {_ilike: $searchString}},
            {last_name: {_ilike: $searchString}},
            {email: {_ilike: $searchString}},
            {album_key: {_ilike: $searchString}},
            {event_hashtags: {_ilike: $searchString}}
          ],
           _and:           
          [
            {active: {_eq: true}}
          ],

        }
      ) 
      {
        ...Order
      }
    }
    ${fragments.order}
  `;

const SUBSCRIBE_TO_SEARCHED_MOVES = gql`
subscription subscribe_to_searched_orders(
    $searchString: String!
    $searchInt: bigint!
    $searchNumeric: numeric!
) {
    orders(
        order_by: {updatedat: desc}, 
        where: {
            _or: [
              {id: {_eq: $searchInt}}, 
              {booth_id: {_eq: $searchInt}}, 
              {event_name: {_ilike: $searchString}}, 
              {outbound_tracking: {_ilike: $searchString}}, 
              {inbound_tracking: {_ilike: $searchString}}, 
              {extra_tracking: {_ilike: $searchString}},
              {shipping_address1: {_ilike: $searchString}},
              {shipping_address2: {_ilike: $searchString}},
              {shipping_city: {_ilike: $searchString}},
              {shipping_state: {_ilike: $searchString}},
              {shipping_zip: {_ilike: $searchString}},
              {shopify_order_id: {_eq: $searchNumeric}},
              {gallery_url: {_ilike: $searchString}},
              {first_name: {_ilike: $searchString}},
              {last_name: {_ilike: $searchString}},
              {email: {_ilike: $searchString}},
              {album_key: {_ilike: $searchString}},
              {event_hashtags: {_ilike: $searchString}}
            ],
             _and:           
            [
              {active: {_eq: true}}
            ],

        }
      ) {
      ...Order
    }
}
${ fragments.order}
`;

// Values for dropdown which will be used to determine date range
const filterOptions = [
    { name: "today", label: "today" },
    { name: "week to date", label: "week to date" },
    { name: "month to date", label: "month to date" },
    { name: "year to date", label: "year to date" },
    { name: "all time", label: "all time" },
    { name: "custom range", label: "custom range" },
];

// Custom CSS to inject into className as classes.<class defined below> (e.g. className={classes.textField})
const styles = theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
    textField: {
        marginTop: "0px !important",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: "0px !important",
        // width: 200,
    },
    menuItem: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
    },
});


class SearchResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };

    // Sets dates from local storage - if no range is stored it will default to all time
    componentWillMount = () => {
    };

    render() {
        const { classes } = this.props;
        const searchString = "%"+this.props.location.state.search+"%";
        const searchInt = isNaN(Number(this.props.location.state.search))===true? 0 : Number(this.props.location.state.search);
        const searchNumeric = isNaN(Number(this.props.location.state.search))===true? 0 : Number(this.props.location.state.search);
        //console.log('searchInt', searchInt)
        return (
            <div className={classes.root}>
                <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography inline="true" variant="h6">
                            Show Results for "{this.props.location.state.search}"
                        </Typography>
                    </Grid>
                </Grid>

                {/* Query the GQL server based on the selected date range and return all corresponding orders */}
                <Query
                    query={SEARCH_MOVES}
                    variables={
                        {
                            searchString: searchString,
                            searchInt: searchInt,
                            searchNumeric: searchNumeric
                        }
                    }>
                    {({ subscribeToMore, ...result }) => {
                        if (log) console.log("result:", result)
                        if (result.loading) return <Loading />;
                        if (result.error) return `Error! ${result.error.message}`;
                        return (
                            <OrdersTableView
                                // Pass a subscribeToMore function to the child component as a prop that will be called on componentDidMount
                                //  This will initialize the subscription to keep the cache up to date with current data 
                                subscribeToNewOrders={() =>
                                    subscribeToMore({
                                        document: SUBSCRIBE_TO_SEARCHED_MOVES,
                                        variables: { 
                                            searchString: searchString,                             
                                            searchInt: searchInt,
                                            searchNumeric: searchNumeric},
                                        updateQuery: (prev, { subscriptionData }) => {
                                            if (log) console.log("subscriptionData:", subscriptionData, "prev:", prev);
                                            if (!subscriptionData.data) return prev;
                                            return Object.assign({}, prev, subscriptionData.data)
                                        }
                                    })
                                }
                                // Feed the array of orders returned from the query as a prop, this will update automatically due to the subscribeToMore function called in child
                                orders={Array.from(result.data.orders || [])}
                            />
                        );
                    }}
                </Query>
                </Container>
            </div>
        )
    }
}

SearchResults.contextType = GlobalContext;

SearchResults.propTypes = {
    classes: PropTypes.object.isRequired,
};

// withStyles makes the custom CSS classes defined above available as classNames
export default withStyles(styles)(SearchResults);
