import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';
import { makeStyles, withStyles, Grid, Typography, Tooltip, Switch, InputBase, FormControl, InputLabel, TextField, InputAdornment, Icon, Select, MenuItem, Button, Chip } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const log = true;

export default function OrderDetailsInfo({ order, onChange }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [newOrder, setNewOrder] = useState(order)

  useEffect(() => {
    if (onChange) onChange(newOrder)
  }, [newOrder])

  const handleInputChange = propName => event => {
    const value = event.target ? event.target.value : moment(event).format('YYYY-MM-DD')
    console.log(`Edited field ${propName} to ${value}`)
    updateOrderProp(propName, value)
  }

  const updateOrderProp = (propName, value) => {
    let tempOrder = {}
    Object.assign(tempOrder, newOrder)
    tempOrder[propName] = value
    setNewOrder(tempOrder)
  }

  const AntSwitch = withStyles(theme => ({
    root: {
      verticalAlign: 'top',
      float: 'right',
      display: 'flex',
      width: '36px',
      height: '17px',
      padding: 0,
      transition: '0.2s',
    },
    switchBase: {
      padding: '2px',
      color: theme.palette.secondary.light,
      '&$checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + $track': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '13px',
      height: '13px',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #ddd`,
      borderRadius: '8.5px',
      backgroundColor: '#fff',
      opacity: 1,
    },
    checked: {},
  }))(Switch);
console.log(order)
  return (<>
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Event&nbsp;Name:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.event_name || ''} onChange={handleInputChange('event_name')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} /> 
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>First&nbsp;Name:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.first_name || ''} onChange={handleInputChange('first_name')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Last&nbsp;Name:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.last_name || ''} onChange={handleInputChange('last_name')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Email:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.email || ''} onChange={handleInputChange('email')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Address&nbsp;1:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.shipping_address1 || ''} onChange={handleInputChange('shipping_address1')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Address&nbsp;2:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.shipping_address2 || ''} onChange={handleInputChange('shipping_address2')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>City:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.shipping_city || ''} onChange={handleInputChange('shipping_city')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>State:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.shipping_state || ''} onChange={handleInputChange('shipping_state')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Zip:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.shipping_zip || ''} onChange={handleInputChange('shipping_zip')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
      </Grid>

      <div className={cls.hiddenLineBreak} />

      <Grid item sm={4} xs={12}>
      <div className={cls.listItem}>
          <Typography className={cls.keyTxt} style={{width:'100%'}}>Open Complaint:&nbsp;&nbsp;</Typography>
          <div className={cls.switch}><AntSwitch checked={newOrder.complaint_pending} onChange={() => updateOrderProp('complaint_pending', !newOrder.complaint_pending)} /></div>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Active:&nbsp;&nbsp;</Typography>
          <div className={cls.switch}><AntSwitch checked={newOrder.active} onChange={() => updateOrderProp('active', !newOrder.active)} /></div>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Status:&nbsp;&nbsp;</Typography>
          <Select
            id="status-select"
            value={newOrder.status}
            onChange={handleInputChange('status')}
            input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="New">New</MenuItem>
            <MenuItem value="Ready">Ready</MenuItem>
            <MenuItem value="Preparing">Preparing</MenuItem>
            <MenuItem value="Outbound">Outbound</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
            <MenuItem value="Packing">Packing</MenuItem>
            <MenuItem value="Inbound">Inbound</MenuItem>
            <MenuItem value="Returned">Returned</MenuItem>
            <MenuItem value="Checkin">Checkin</MenuItem>
            <MenuItem value="Done">Done</MenuItem>
            <MenuItem value="Canceled">Canceled</MenuItem>
          </Select>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Booth:&nbsp;&nbsp;</Typography>
          <InputBase value={newOrder.booth_id || 0} onChange={handleInputChange('booth_id')} className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
        <Typography className={cls.keyTxt}>Custom&nbsp;Logo:&nbsp;&nbsp;</Typography>
          <div className={cls.switch}><AntSwitch checked={newOrder.custom_logo} onChange={() => updateOrderProp('custom_logo', !newOrder.custom_logo)} /></div>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Props:&nbsp;&nbsp;</Typography>
          <Select
            id="props-select"
            value={newOrder.props || 'Default'}
            onChange={handleInputChange('props')}
            input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
          >
            <MenuItem value="Default">Default</MenuItem>
            <MenuItem value="Wedding">Wedding</MenuItem>
            <MenuItem value="New Years">New Years</MenuItem>
            <MenuItem value="Graduation">Graduation</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Print&nbsp;Count:&nbsp;&nbsp;</Typography>
          <Select
            id="print-count-select"
            value={newOrder.print_count || 0}
            onChange={handleInputChange('print_count')}
            input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
          >
            <MenuItem value="0">0 (Digital)</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="200">200</MenuItem>
            <MenuItem value="300">300</MenuItem>
            <MenuItem value="400">400</MenuItem>
            <MenuItem value="500">500</MenuItem>
            <MenuItem value="600">600</MenuItem>
            <MenuItem value="700">700</MenuItem>
            <MenuItem value="800">800</MenuItem>
          </Select> 
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Print&nbsp;Layout:&nbsp;&nbsp;</Typography>
          <Select
            id="print-layout-select"
            value={newOrder.print_layout || 0}
            onChange={handleInputChange('print_layout')}
            input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
          >
            {ctx.BoothByMail.printLayouts.map((layoutName, layoutId) => (
              <MenuItem key={layoutId} value={layoutId}>{layoutName}</MenuItem>
            ))}
          </Select>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Backdrop:&nbsp;&nbsp;</Typography>
          <Select
            id="backdrop-select"
            value={newOrder.backdrop_color || ''}
            onChange={handleInputChange('backdrop_color')}
            input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
          >
            <MenuItem value="Champagne">Champagne</MenuItem>
            <MenuItem value="Silver">Silver</MenuItem>
            <MenuItem value="Chevron">Chevron</MenuItem>
            <MenuItem value="Black">Black</MenuItem>
          </Select> 
        </div>
      </Grid>

      <div className={cls.hiddenLineBreak} />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item sm={4} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Ship&nbsp;Date:&nbsp;&nbsp;</Typography>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              format="yyyy-MM-dd"
              margin="dense"
              variant="inline"
              value={moment(newOrder.ship_date).format('YYYY-MM-DD')}
              onChange={handleInputChange(`ship_date`)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
            />
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Arrival&nbsp;Date:&nbsp;&nbsp;</Typography>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              format="yyyy-MM-dd"
              margin="dense"
              variant="inline"
              value={moment(newOrder.arrival_date).format('YYYY-MM-DD')}
              onChange={handleInputChange(`arrival_date`)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
            />
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Event&nbsp;Date:&nbsp;&nbsp;</Typography>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              format="yyyy-MM-dd"
              margin="dense"
              variant="inline"
              value={moment(newOrder.event_date).format('YYYY-MM-DD')}
              onChange={handleInputChange(`event_date`)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
            />
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Return&nbsp;Ship&nbsp;Date:&nbsp;&nbsp;</Typography>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              format="yyyy-MM-dd"
              margin="dense"
              variant="inline"
              value={moment(newOrder.return_ship_date).format('YYYY-MM-DD')}
              onChange={handleInputChange(`return_ship_date`)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
            />
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Return&nbsp;Date:&nbsp;&nbsp;</Typography>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              format="yyyy-MM-dd"
              margin="dense"
              variant="inline"
              value={moment(newOrder.return_date).format('YYYY-MM-DD')}
              onChange={handleInputChange(`return_date`)}
              className={cls.nakedValTxt}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              input={<InputBase className={cls.nakedValTxt} inputProps={{ className: cls.nakedValInput }} />}
            />
          </div>
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  </>)
}

const useStyles = makeStyles(theme => ({
  list: {

  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  tinyBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#eee',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
}));