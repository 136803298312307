import gql from 'graphql-tag';

// ------------------------------------------------- Order Object -------------------------------------------------
const orderFragment = () => gql`
  fragment Order on orders {
    active
    id
    status
    event_name
    email
    first_name
    last_name
    ship_date
    arrival_date
    event_date
    return_ship_date
    return_date
    outbound_tracking
    inbound_tracking
    extra_tracking
    service_level
    shipping_address1
    shipping_address2
    shipping_city
    shipping_state
    shipping_zip
    transit_days
    booth_id
    booth_sku
    backdrop_color
    complaint_pending
    custom_logo
    logo_url
    print_count
    print_layout
    props
    shopify_order_id
    gallery_url
    album_key
    event_hashtags
    createdat
    updatedat
  }
`;
// ------------------------------------------------- Order with Children Object -------------------------------------------------
const orderWithChildrenFragment = () => gql`
  fragment OrderWithChildren on orders {
    active
    id
    status
    event_name
    email
    first_name
    last_name
    ship_date
    arrival_date
    event_date
    return_ship_date
    return_date
    outbound_tracking
    inbound_tracking
    extra_tracking
    service_level
    shipping_address1
    shipping_address2
    shipping_city
    shipping_state
    shipping_zip
    transit_days
    booth_id
    booth_sku
    backdrop_color
    complaint_pending
    custom_logo
    logo_url
    print_count
    print_layout
    props
    shopify_order_id
    gallery_url
    album_key
    event_hashtags
    outbound_shipment_activity {
      ...ShipmentActivity
    }
    inbound_shipment_activity {
      ...ShipmentActivity
    }
    extra_shipment_activity {
      ...ShipmentActivity
    }
    notes(order_by: { createdat: desc }) {
      ...Notes
    }
    logs(order_by: { createdat: asc }) {
      ...Logs
    }
    sessions(order_by: { createdat: desc }) {
      ...SessionsWithPhotos
    }
    sms_shares(order_by: { createdat: desc }) {
      ...ShareSMS
    }
    email_shares(order_by: { createdat: desc }) {
      ...ShareEmail
    }
    createdat
    updatedat
  }
  ${shipmentActivityFragment()}
  ${notesFragment()}
  ${logsFragment()}
  ${sessionsWithPhotosFragment()}
  ${shareSMSFragment()}
  ${shareEmailFragment()}
`;
// ------------------------------------------------- SHipping Activity Object -------------------------------------------------
const sessionsWithPhotosFragment = () => gql`
  fragment SessionsWithPhotos on sessions {
    id
    order_id
    layout_id
    createdat
    photos(order_by: { sequence: asc }) {
      ...Photos
    }
  }
  ${photosFragment()}
`;
// ------------------------------------------------- SHipping Activity Object -------------------------------------------------
const photosFragment = () => gql`
  fragment Photos on photos {
    session_id
    id
    name
    key
    sequence
    smugmug_uri
    thumb_url
    thumb_height
    thumb_width
    thumb_size
    full_url
    full_height
    full_width
    full_size
    createdat
  }
`;
// ------------------------------------------------- SHipping Activity Object -------------------------------------------------
const shipmentActivityFragment = () => gql`
  fragment ShipmentActivity on shipping_activity_cache {
    json
    jsonb
    shipped
    delivered
    pickup_date
    delivery_date
    transit_time_json
    estimated_arrival
    scheduled_delivery_date
    transit_business_days
    service_level
    latest_status_type
    latest_status_code
    latest_status_description
    updatedat
  }
`;
// ------------------------------------------------- Notes Object -------------------------------------------------
const notesFragment = () => gql`
  fragment Notes on notes {
    id
    user {
      id
      name
    }
    type
    body
    createdat
  }
`;
// ------------------------------------------------- Logs Object -------------------------------------------------
const logsFragment = () => gql`
  fragment Logs on logs {
    id
    order_id
    session_id
    booth_id
    user_id
    type
    message
    createdat
  }
`;
// ------------------------------------------------- SMS Shares Object -------------------------------------------------
const shareSMSFragment = () => gql`
  fragment ShareSMS on share_sms {
    id
    order_id
    session_id
    phone_number
    status
    createdat
    updatedat
    log {
      id
      delivered
      error
      tries
      created_at
    }
  }
`;
// ------------------------------------------------- Email Shares Object -------------------------------------------------
const shareEmailFragment = () => gql`
  fragment ShareEmail on share_email {
    id
    order_id
    session_id
    email
    status
    createdat
    updatedat
    log {
      id
      delivered
      error
      tries
      created_at
    }
  }
`;

const fragments = {
  order: orderFragment(),
  orderWithChildren: orderWithChildrenFragment(),
  shipmentActivity: shipmentActivityFragment(),
  notes: notesFragment(),
  logs: logsFragment(),
  sessionsWithPhotos: sessionsWithPhotosFragment(),
  photos: photosFragment(),
  shareSMS: shareSMSFragment(),
  shareEmail: shareEmailFragment(),
};

export default fragments;