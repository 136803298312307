import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import boothbymail from '@BoothByMail/boothbymail-sdk';
import moment from 'moment';
import { GlobalContext } from '../../global-context';
import { makeStyles, Container, Typography, Button, Tooltip, Grid, Paper, Icon, Chip } from '@material-ui/core';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import Loading from '../utils/Loading';
import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';
import { ExportToCsv } from 'export-to-csv';


const log = true;

const defaultOrder = `asc`;
const defaultOrderBy = `ID`;

function OpenComplaint(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const applyFilters = (data) => {
    if (!search || search.length < 1) return data;
    else {
      return data.filter(o => {
        if (
          (o.event_name && o.event_name.toLocaleLowerCase().includes(search)) ||
          (o.id && (o.id + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address1 && (o.shipping_address1 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_address2 && (o.shipping_address2 + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_city && (o.shipping_city + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_state && (o.shipping_state + ``).toLocaleLowerCase().includes(search)) ||
          (o.shipping_zip && (o.shipping_zip + ``).toLocaleLowerCase().includes(search)) ||
          (o.outbound_tracking && (o.outbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.inbound_tracking && (o.inbound_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.email && (o.email + ``).toLocaleLowerCase().includes(search)) ||
          (o.first_name && (o.first_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.last_name && (o.last_name + ``).toLocaleLowerCase().includes(search)) ||
          (o.extra_tracking && (o.extra_tracking + ``).toLocaleLowerCase().includes(search)) ||
          (o.booth_id && (o.booth_id + ``).toLocaleLowerCase().includes(search))
        ) {
          return true;
        }
        else return false;
      })
    }
  }


  const generateCSV = (orders) => {
    const createCsvRow = (order) => {
      return {
        EVENT_NAME: order.event_name ? order.event_name : `-`,
        ID: order.id,
        COMPLAINT: order.complaint_pending,
      }
    }
    const csvRows = orders.map(order => createCsvRow(order));
    const csvOptions = {
      filename: `BBM_open_complaints`,
      showTitle: true,
      title: `BBM order complaints`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  const goToOrder = (orderId) => {
    props.history.push(`/orders/${orderId}`);
  }

  const getTableActions = (orders) => {
    return [
      { label: `Generate\xa0CSV`, handler: () => generateCSV(orders) },
    ];
  }
  const getRowActions = (order) => {
    return [
      { label: `View\xa0Order\xa0Details`, handler: () => goToOrder(order.id) },
    ];
  }

  const handleResolveComplaint = async (order) => {
    let unsavedOrder={...order}
    try {
        unsavedOrder.complaint_pending=false
        console.log(unsavedOrder)
      let diff = boothbymail.utils.diff(order, unsavedOrder);
      diff.id = order.id;
      console.log('Saving changes to order info: ', diff);
      const res = await boothbymail.orders.update(diff);
      if (res) {
        const resOrder = res.data.update_orders.returning[0];
        log && console.log(`>> UPDATED Order:`, resOrder);
        delete diff.id;
        // await ctx.BoothByMail.orders.addNotes([
        //   {
        //     order_id: order.id,
        //     user_id: ctx.userProfile.bbm_user_id,
        //     type: 'Note',
        //     body: `Resolved Complaint`,
        //   },
        // ]);
        // ctx.handleNotifications(true, `success`, `Order #${resOrder.id} was successfully updated.`);
      }
    } catch (err) {
      console.log(`Failed to update order:`, err);
      ctx.handleNotifications(true, `error`, `Failed to update order: ${err.toString()}`);
    }
  };

  return (<>
    <div className={cls.root}>
      <Container maxWidth="lg">
        <Typography className={cls.headTxt}>Open Complaints</Typography>

        <Subscription subscription={GET_ORDERS()} onError={(err) => ctx.handleNotifications(true, `error`, `Failed to retrieve orders: ${err.toString()}`)}>
          {({ loading, error, data }) => {
            if (loading) return <Loading fixed />
            if (error) {
              console.log(`Failed to retrieve orders:`, error);
              return (
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>ERROR FINDING ORDERS</Typography>
                </div>
              )
            }
            if (data && data.orders && data.orders.length > 0) {
              log && console.log(`Orders for orders index:`, data.orders);
              const filteredOrders = applyFilters(data.orders);
              const headers = [
                { id: `ID`, alignLeft: true, numeric: true, label: <Typography style={{fontWeight:700}}>#</Typography> },
                { id: `EVENT_NAME`, alignLeft: true, numeric: false, label:  <Typography style={{fontWeight:700}}>Name</Typography> },
                { id: `COMPLAINT`, alignLeft: true, numeric: false, label:  <Typography style={{fontWeight:700}}>Action</Typography> },
              ]
              const rows = filteredOrders.map(order => {
                return {
                  ID: <Typography color='primary'>{order.id}</Typography>,
                  EVENT_NAME: <Grid container direction='column'>
                    <Grid item>
                      <Typography>
                      {order.event_name ? order.event_name : `-`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color='primary' style={{fontSize:'75%', fontWeight:500}}>
                        {order.shipping_state} , {order.shipping_city}
                      </Typography>
                    </Grid>
                  </Grid>,
                  COMPLAINT: <Button onClick={e=>{
                    e.stopPropagation()
                    handleResolveComplaint(order)
                }}variant='contained' style={{color:'white', backgroundColor:'#cf5257'}}>Resolve</Button>,
                  order: order
                }
              })

              return (<>

                <AccordianTable
                  title={`${rows.length} Open Complaints`}
                  headers={headers}
                  rows={rows}
                  actions={getTableActions(data.orders)}
                  search={search}
                  defaultOrder={defaultOrder}
                  defaultOrderBy={defaultOrderBy}
                  order={order}
                  orderBy={orderBy}
                  tablePage={tablePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  setSearch={setSearch}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  setTablePage={setTablePage}
                  setRowsPerPage={setRowsPerPage}
                  setExpandedRowId={setExpandedRowId}
                  className={cls.table}
                >
                  {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                    .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <AccordianRow
                        key={`order-index-${row.order.id}`}
                        rowId={row.order.id}
                        expandedRowId={expandedRowId}
                        setExpandedRowId={setExpandedRowId}
                        columns={[
                          { align: 'left', value: row.ID },
                          { align: 'left', value: row.EVENT_NAME },
                          { align: 'left', value: row.COMPLAINT }
                        ]}
                        actions={getRowActions(row.order)}
                        onClick={() => goToOrder(row.ID)}
                        className={!row.order.disputed ? cls.row : cls.rowDisputed}
                      >
                        <div />
                      </AccordianRow>
                    ))}
                </AccordianTable>
              </>)
            }
            else return (
              <div className={cls.notFound}>
                <Typography className={cls.notFoundTxt}>NO ORDERS FOUND</Typography>
              </div>
            )
          }}
        </Subscription>

      </Container>
    </div>
  </>)
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowDisputed: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: '#ffffff',
    boxShadow: 'none',
    "&:hover": {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    color: '#inherit',
    cursor: 'pointer',
  },
  headTxt: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));

const GET_ORDERS = () => (gql`
subscription OpenComplaints {
  orders(
    order_by: {id: asc},
    where: {
      complaint_pending : {_eq: true}
    }
  ) {
    active
    id
    status
    event_name
    first_name
    last_name
    ship_date
    arrival_date
    event_date
    return_ship_date
    return_date
    outbound_tracking
    shipping_address1
    shipping_address2
    shipping_city
    shipping_state
    shipping_zip
    transit_days
    complaint_pending
  }
  }

`);

export default withRouter(OpenComplaint);