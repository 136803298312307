import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { makeStyles, TableContainer, TableHead, TableRow, Table, TableBody, TableCell, Chip, Icon, Paper, Button, Grid, Typography } from '@material-ui/core';
import axios from 'axios';

const log = false;
const {REACT_APP_BBM_API_KEY}=process.env

function OrderDetailsSharing({shareSmsRecords, shareEmailRecords, ...props}) {
  const cls = useStyles();

const resendSms= async(row)=>{
  console.log('clicked!')
  //call to resend sms of session
let payload={
  event:{
    id:null
  },
  session:{
    id:null
  },
  phone:null,
  optIn:null
}
let config={
  headers:{
    'api_key' : REACT_APP_BBM_API_KEY
  }
}
try{
if(shareSmsRecords.length){
  payload.event.id=row.order_id
  payload.session.id=row.session_id
  payload.phone=row.phone_number
  payload.optIn=row.optIn
}const res= await axios.post('/.netlify/functions/share-session-by-sms',payload,config)
console.log(res)
}catch(e){
  console.log(e)
}
}
  return (<>
    <TableContainer>
      <Table className={cls.table} aria-label="sms share events table">
        <TableHead>
          <TableRow>
            <TableCell>Session</TableCell>
            <TableCell align="right">Updated</TableCell>
            <TableCell align="left">Destination</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Delivered</TableCell>
            <TableCell align="left">Error</TableCell>
            <TableCell align="left">Tries</TableCell>
            <TableCell  >Next Retry</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shareSmsRecords && shareSmsRecords.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.session_id}</TableCell>
              <TableCell align="right">{moment.duration(moment().diff(moment(row.createdat))).humanize() + ' ago'}</TableCell>
              <TableCell align="left">{row.phone_number}</TableCell>
              <TableCell align="right"><Chip label={row.status} variant={row.status === 'done' ? 'default' : 'outlined'} size="small" color={row.status === 'done' ? 'primary' : 'secondary'} /></TableCell>
              <TableCell align="right">{row.log && row.log.delivered ? <Icon>check_box</Icon> : <Icon>check_box_outline_blank</Icon>}</TableCell>
              <TableCell align="left">{row.log && row.log.error ? <Icon>check_box</Icon> : <Icon>check_box_outline_blank</Icon>}</TableCell>
              <TableCell align="left">{row.log && row.log.tries}</TableCell>
              <TableCell align="right" >
                <Grid container direction='row' spacing={1}>
                  <Grid item container alignContent='center' style={{width:'auto'}} >
                    <Typography>{row.log && row.log.next_retry_at && moment.duration(moment().diff(moment(row.log.next_retry_at))).humanize() + ' ago'}</Typography>
                  </Grid>
                  <Grid item>
                    <Button variant='contained'color='primary' onClick={()=>resendSms(row)} >
                      Retry
                    </Button>
                  </Grid>
              </Grid>
              </TableCell>

            </TableRow>
          ))}
          {shareEmailRecords && shareEmailRecords.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.session_id}</TableCell>
              <TableCell align="right">{moment.duration(moment().diff(moment(row.createdat))).humanize() + ' ago'}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="right"><Chip label={row.status} variant={row.status === 'done' ? 'default' : 'outlined'} size="small" color={row.status === 'done' ? 'primary' : 'secondary'} /></TableCell>
              <TableCell align="right">{row.log && row.log.delivered ? <Icon>check_box</Icon> : <Icon>check_box_outline_blank</Icon>}</TableCell>
              <TableCell align="left">{row.log && row.log.error ? <Icon>check_box</Icon> : <Icon>check_box_outline_blank</Icon>}</TableCell>
              <TableCell align="left">{row.log && row.log.tries}</TableCell>
              <TableCell align="right">{row.log && row.log.next_retry_at && moment.duration(moment().diff(moment(row.log.next_retry_at))).humanize() + ' ago'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>)
}

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
}))

export default withRouter(OrderDetailsSharing);