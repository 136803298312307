import React from 'react';
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { makeStyles, Typography, Tooltip, Icon, IconButton, Button, Box, Paper, Grid, Card, CardContent, Chip } from '@material-ui/core';

const printCountChipColors = {100: 'lightseagreen', 200: 'steelblue', 300: 'forestgreen', 400: 'darkorange'}
const backdropChipColors = {'Champagne': 'wheat', 'Silver': 'lightgrey', 'Black': 'darkgrey', 'Chevron': 'lightsteelblue'}

function OrderCard({ order }) {
  const isPast= ()=>{
    let isPast
    try{
      if(order.status==='Outbound'){
      isPast= !moment().isBefore(order.ship_date)
      }else{
        isPast= !moment().isBefore(order.return_date)
      }
    }catch(e){console.log(e)}
    return isPast
  }
  

    const cls = useStyles();
    return (
        <Link to={`/orders/${order.id}`}>
            <Card className={isPast()&&cls.greyed} >
                <CardContent  style={{ padding: '8px' }}>
                    <Grid container className={cls.rowTxt}>
                        <Grid item xs={6} className={cls.cardHeadTxt}>
                            {order.event_name}
                        </Grid>
                        <Grid item xs={6} className={`${cls.cardHeadTxt} ${cls.alignRight}`}>
                            {order.id}
                        </Grid>
                        <Grid item xs={12}>
                            <div className={cls.listItem}>
                                <Typography className={cls.keyTxt}>{order.shipping_city}, {order.shipping_state}</Typography>
                                <Typography className={cls.valTxt}>{order.transit_days} days</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={cls.lineBreak} />
                            <div className={cls.listItem}>
                                <Typography className={cls.keyTxt}>{moment(order.ship_date).format('ddd MMM Do')}</Typography>
                                <Typography className={cls.valTxt}>{moment(order.event_date).format('ddd MMM Do')}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={cls.lineBreak} />
                            <div className={cls.listItem}>
                                <Typography className={cls.keyTxt}>Props:</Typography>
                                <Typography className={cls.valTxt}>{order.props}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={cls.chips}>
                            <Tooltip title={`${order.print_count} prints`}><Chip size="small" color="primary" style={{ backgroundColor: printCountChipColors[order.print_count] }} label={order.print_count} icon={<Icon fontSize='small'>print</Icon>} /></Tooltip>
                            <Tooltip title={order.custom_logo ? 'Custom logo' : 'Default logo'}><Chip size="small" color={order.custom_logo ? 'primary' : 'default'} label={order.custom_logo ? 'Custom' : 'Default'} icon={<Icon fontSize='small'>filter_vintage</Icon>} /></Tooltip>
                            <Tooltip title={`${order.backdrop_color} backdrop`}><Chip size="small" color={order.backdrop_color !== 'Silver' ? 'default' : 'primary'} style={{ backgroundColor: backdropChipColors[order.backdrop_color] }} label={order.backdrop_color} icon={<Icon fontSize='small'>bookmark_border</Icon>} /></Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Link>
    )
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '3px',
    marginBottom: '4px',
    background: '#eee',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: '12px',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '4px',
    },
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  rowTxt: {
    color: 'inherit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  cardHeadTxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  greyed:{
    backgroundColor:`#d3d3d3`,
    filter:'grayscale(100%)',
    opacity:.6
  }
}));

export default withRouter(OrderCard);