import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';
import { makeStyles, withStyles, Grid, Typography, Tooltip, Switch, InputBase, FormControl, InputLabel, TextField, InputAdornment, Icon, Select, MenuItem, Button, Chip } from '@material-ui/core';


const log = true;

export default function OrderDetailsInfo({ order }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const AntSwitch = withStyles(theme => ({
    root: {
      verticalAlign: 'top',
      float: 'right',
      display: 'flex',
      width: '36px',
      height: '17px',
      padding: 0,
      transition: '0.2s',
    },
    switchBase: {
      padding: '2px',
      color: theme.palette.secondary.light,
      '&$checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + $track': {
          borderColor: theme.palette.secondary.light,
          backgroundColor: theme.palette.secondary.light,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '13px',
      height: '13px',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #ddd`,
      borderRadius: '8.5px',
      backgroundColor: '#fff',
      opacity: 1,
    },
    checked: {},
  }))(Switch);

  const formatAddress = () => {
    let addressStr = ''
    if (!order) return ''
    addressStr += order.shipping_address1
    if (order.shipping_address2) {
      addressStr += `, ${order.shipping_address2}`
    }
    addressStr += `, ${order.shipping_city}`
    addressStr += ` ${order.shipping_state.toUpperCase()}`
    addressStr += ` ${order.shipping_zip.toUpperCase()}`
    return addressStr
  }

  return (<>

    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Event&nbsp;Name:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.event_name || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>First&nbsp;Name:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.first_name || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Last&nbsp;Name:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.last_name || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Email:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.email || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Address:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{formatAddress()}</Typography>
        </div>
      </Grid>

      <div className={cls.hiddenLineBreak} />

      <Grid item sm={4} xs={12}>
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Active:&nbsp;&nbsp;</Typography>
          <div className={cls.switch}><AntSwitch checked={order.active} /></div>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Status:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.status || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Booth:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.booth_id || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Custom&nbsp;Logo:&nbsp;&nbsp;</Typography>
          <div className={cls.switch}><AntSwitch checked={ order.custom_logo} /></div>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Props:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.props || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Print&nbsp;Count:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.print_count || `Digital`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Print&nbsp;Layout:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{(order.print_layout < 0 || order.print_layout >= ctx.BoothByMail.printLayouts.length) ? `-` : ctx.BoothByMail.printLayouts[order.print_layout]}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Backdrop:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.backdrop_color || `-`}</Typography>
        </div>
      </Grid>

      <div className={cls.hiddenLineBreak} />

      <Grid item sm={4} xs={12}>
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Ship&nbsp;Date:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.ship_date || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Arrival&nbsp;Date:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.arrival_date || `-`}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Event&nbsp;Date:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.event_date}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Return&nbsp;Ship&nbsp;Date:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.return_ship_date}</Typography>
        </div>
        <div className={cls.lineBreak} />
        <div className={cls.listItem}>
          <Typography className={cls.keyTxt}>Return&nbsp;Date:&nbsp;&nbsp;</Typography>
          <Typography className={cls.valTxt}>{order.return_date || `-`}</Typography>
        </div>
      </Grid>
    </Grid>
  </>)
}

const useStyles = makeStyles(theme => ({
  info: {
    display: 'block',
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  infoImage: {
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    width: '200px',
    height: '200px',
    border: `1px solid #cdd6e5`,
    borderRadius: '5%',
    background: `#cdd6e5`,
    verticalAlign: 'top',
  },
  infoSpacer: {
    display: 'block',
    position: 'relative',
    width: '128px',
    height: '1px',
  },
  infoNoVehicleTxt: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 700,
    cursor: 'default',
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
    cursor: 'default',
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  list: {

  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  input: {
    margin: 0,
  },
  btn: {
    verticalAlign: 'top',
    minHeight: '40px',
    maxHeight: '40px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    "&:hover": {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    "&:disabled": {
      color: '#fff',
      backgroundColor: theme.palette.secondary.light,
    },
  },
  tags: {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tag: {
    marginRight: theme.spacing(1),
    color: `#fff`,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  tinyBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#eee',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
}));