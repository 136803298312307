import React, { useContext, useState, useEffect } from 'react';
import {
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Dialog,
  DialogActions,
  Button,
} from '@material-ui/core';
import { GlobalContext } from '../../../global-context';
import boothbymail from '@BoothByMail/boothbymail-sdk';

let log = false;

export default function InboundModal({ open, setOpen, order }) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [loading, setLoading] = useState(false);

  const handleStartInbound = async () => {
    try {
      boothbymail.orders.updateStatus(order.id, 'Checkin');
      ctx.handleNotifications(true, `success`, `Inbound successfully started`);
      await ctx.BoothByMail.orders.addNotes([
        {
          order_id: order.id,
          user_id: ctx.userProfile.bbm_user_id,
          type: 'Note',
          body: `Inbound started`,
        },
      ]);
    } catch (err) {
      console.error(`Failed to update order:`, err);
      ctx.handleNotifications(true, `error`, `Failed to start inbound: ${err.toString()}`);
    }
  };

  const handleFinishInbound = async () => {
    try {
      boothbymail.orders.updateStatus(order.id, 'Done');
      ctx.handleNotifications(true, `success`, `Inbound successfully started`);
      await ctx.BoothByMail.orders.addNotes([
        {
          order_id: order.id,
          user_id: ctx.userProfile.bbm_user_id,
          type: 'Note',
          body: `Inbound completed`,
        },
      ]);
    } catch (err) {
      console.error(`Failed to update order:`, err);
      ctx.handleNotifications(true, `error`, `Failed to complete inbound: ${err.toString()}`);
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Inbound</DialogTitle>
      <DialogContent>
        <DialogContentText>
          When you're ready, hit the start button to set the status of the order to Checkin to begin the inbound
          process. The customer will not be notified of this change.
          <br />
          <br />
          <Button disabled={order.status === 'Preparing'} color='primary' onClick={() => handleStartInbound()}>
            Start
          </Button>
          <br />
          <br />
          When inbound is complete, hit the done button to mark the status of the order to done and trigger the order
          completed customer notifitcation.
        </DialogContentText>
        {loading ? (
          <CircularProgress
            style={{
              display: 'block',
              margin: 'auto',
              position: 'absolute',
              left: '0',
              right: '0',
            }}
          />
        ) : (
          <>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={order.status === 'Checkin'} color='primary' onClick={() => handleStartInbound()}>
          Start
        </Button>
        <Button disabled={order.status !== 'Checkin'} color='primary' onClick={() => handleFinishInbound()}>
          Done
        </Button>
        <Button disabled={loading} color='secondary' onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
}));
